import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import AuthNav from '../../../nav/AuthNav'

import currentStepIcon from '../../../../assets/svg/current-step-icon.svg'
import doneStepIcon from '../../../../assets/svg/step-done-icon.svg'
import pendingIcon from '../../../../assets/svg/pending-submission-icon.svg'
import goBack from '../../../../assets/svg/go-back.svg'
import AssignDispatchRider from './AssignRider'
import SelectLab from './SelectLab'
import ScanKit from './ScanKit'
import APP_URL from '../../../../util/api'
import { scrollToTop } from '../../../../util/helper'
import Loader from '../../../_common/loader/Loader';

const baseUrl = APP_URL;


class AssignKit extends Component {
  state = {
    currentPage: 0,
    isLoading: false,
  }

  componentDidMount() {
    const { match : { params }} = this.props;
    this.setState({
      id: params.id
    })
    let token = JSON.parse(localStorage.getItem('access_token'));
    axios.get(`${baseUrl}/admin/cases/${params.id}?token=${token}`)
      .then(({ data }) => {
        const tester = data.data;
        const { firstName, lastName } = tester;
        this.setState({ firstName, lastName })
      })
      .catch(error => {
        console.log(error.response);
      })
  }

  setCurrentPage = (page) => {
    this.setState({currentPage: page}, () => scrollToTop())
  }

  assignToTester = (key, value) => {
    this.setState({
      [key]: value
    }, () => console.log(this.state))
  }

  assignKit = async () => {
    this.setState({isLoading: true});
    const { id, riderId, labId, kitCode } = this.state;
    let requestBody = {
      riderId,
      kitCode,
    }
    if (labId) requestBody = { ...requestBody, labId };

    let token = JSON.parse(localStorage.getItem('access_token'));

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    
    await axios.post(`${baseUrl}/admin/assign/case/${id}`, requestBody, config)
      .then(({data}) => {
        this.props.history.push({
          pathname: '/testers'
        })
      })
      .catch(error => {
        console.error(error.response)
      })

    this.setState({isLoading: false})
  }

  render() {
    let {
      currentPage,
      isLoading,
      firstName,
      lastName
    } = this.state;

    return (
      <main className="main-bg">
        <AuthNav page="testers" />
        <section className="app-section assign-kit">
          <div className="container">
            <div className="back">
              <img alt="" src={goBack} onClick={e => this.props.history.goBack()} />
            </div>

            <div className="header">
              <div className="flex justifyBetween hr-down">
                <h4>Assign a kit to <span className="bold">{firstName} {lastName}</span></h4>

                <ul className="flex assign-kit-steps steps">
                  <li>
                    <div className="flex"
                    // onClick={currentPage > 0 ? () => this.setCurrentPage(0) : null}
                    >
                      {currentPage === 0 || currentPage < 0 ? (
                        <img alt="" src={currentStepIcon} />
                      ): (
                        <img alt="" src={doneStepIcon} />
                      )}
                      
                      <p>Assign Dispatch Rider</p>
                    </div>
                    
                  </li>

                  <li>
                    <div className="flex">
                    {(currentPage !== 1 && currentPage < 1) && (
                        <img src={pendingIcon} alt="" />
                      )}
                      {currentPage === 1 &&  (
                        <img src={currentStepIcon} alt="" />
                      )} 
                      {currentPage > 1 && (
                        <img src={doneStepIcon} alt="" />
                      )}
                      <p>Select a Lab</p>
                    </div>
                  </li>

                  <li>
                    <div className="flex">
                      {(currentPage !== 2 && currentPage < 2) && (
                          <img src={pendingIcon} alt="" />
                      )}
                      {currentPage === 2 &&  (
                        <img src={currentStepIcon} alt="" />
                      )} 
                      {currentPage > 2 && (
                        <img src={doneStepIcon} alt="" />
                      )}
                      <p>Scan a Kit</p>
                    </div>
                    
                  </li>
                </ul>
              </div>
            </div>
            
           {isLoading && <Loader />}

            {
              currentPage === 0 &&
              <AssignDispatchRider
                currentPage={currentPage}
                setPage={this.setCurrentPage}
                assignToTester={this.assignToTester} />
            }
            {
              currentPage === 1 &&
              <SelectLab
                currentPage={currentPage}
                setPage={this.setCurrentPage}
                assignToTester={this.assignToTester} />
            }
            {
              currentPage === 2 &&
              <ScanKit
                currentPage={currentPage}
                setPage={this.setCurrentPage}
                name={`${firstName} ${lastName}`}
                assignToTester={this.assignToTester}
                assignKit={this.assignKit} />
            }
          </div>
        </section>
      </main>
    )
  }
}

export default withRouter(AssignKit);