import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import Modal from '../../modal/Modal';
import Reschedule from '../../dashboard/superAdmin/Testers/Reschedule';


class FailedDeliveriesTable extends Component {
  state = {
    tester: null
  }

  setRescheduleItem = (tester) => {
    this.setState({
      tester
    }, () => this.openModal())
  }

  openModal = () => {
    this.setState({
      rescheduleModal: true,
    })
  }

  closeModal = () => {
    this.setState({
      rescheduleModal: false,
    })
  }

  onSuccessClose = () => {
    this.closeModal();
    this.props.fetchCases();
  }

  navigate = (path) => {
    this.props.history.push({
      pathname: path
    })
  }

  render() {
    const { data } = this.props;
    const { tester, rescheduleModal } = this.state;
    return (
      <div className="table dispatchTable">
        <table>
          <thead>
            <tr>
              <th>Name & Phone Number</th>
              <th>Location</th>
              <th>Dispatch Rider</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          
          <tbody>
            {data && data.map((item, index) => {
              let caseFirstName = (item.case && item.case.firstName) ? item.case.firstName : " ";
              let caseLastName = (item.case && item.case.lastName) ? item.case.lastName : " ";
              let personnelFirstName = (item.personnel && item.personnel.firstName) ? item.personnel.firstName : " ";
              let personnelLastName = (item.personnel && item.personnel.lastName) ? item.personnel.lastName : " ";
              return (
                <tr className="tr" key={index}>
                  <td>
                  <div className="flex flexColumn name-email">
                    <div>
                      {caseFirstName} { " "} {caseLastName} 
                    </div>
                    <div>{item.case.phone_no}</div>
                  </div>
                  </td>
                  <td>{item.case.deliveryAddress}</td>
                  <td>{personnelFirstName} {" "} {personnelLastName}</td>
                  <td><button className="reschedule-btn" onClick={() => this.setRescheduleItem(item)}>Reschedule</button></td>
                  <td><button className="view-btn" onClick={() => this.navigate(`/tester/profile/${item.case.id}`)}>View</button></td>
                </tr>
              )
            })}
              
            {
              data && data.length === 0 && 
              <tr>
                <td colSpan="5">
                  <h3 style={{padding:'30px', textAlign:'center'}}>No failed deliveries at the moment.</h3>
                </td>
              </tr>
            }
          </tbody>
        </table> 
        <Modal show={rescheduleModal} handleClose={this.closeModal}>
          {
          tester && 
          <Reschedule 
            id={tester.case.id}
            deliveryDate={tester.case.deliveryDate}
            firstName={tester.case.firstName}
            lastName={tester.case.lastName}
            onSuccessClose={this.onSuccessClose}
            closeModal={this.closeModal}
          />
          }
        </Modal>         
      </div>  
    )
  }
}

  export default withRouter(FailedDeliveriesTable);