import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthNav from '../../../nav/AuthNav'
import Button from '../../../_common/Button'
import AllDeliveries from '../../../_common/table/AllDeliveriesTable'
import SearchFilter from '../../../_common/form/SearchFilter'
import { GoBackIcon } from '../../../_common/Icons';
import APP_URL from '../../../../util/api'
import AllDispatchLabsContainer from './AllDispatchLabsContainer';
import Loader from '../../../_common/loader/Loader';

const baseUrl = APP_URL;


const style={
  LinkStyle: {
    textDecoration: "none",
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#18131A",
  }, 
  AvatarP: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '25px',
    marginLeft: '8px'
  }
}

class DetailProfile extends Component {
  state = {
    currentPage: 0,
    id: '',
    token: '',
    areas: [],
    isLoading: false,
  }

  componentDidMount() {
    this.fetchData();
    this.fetchLGAS();
  }

  fetchData = async () => {
    const { match : { params }} = this.props;
    if(!params.id) {
      alert("Provide an id parameter")
      return 
    }

    let token = JSON.parse(localStorage.getItem('access_token'));

    this.setState({id: params.id, token, isLoading: true})

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let profileDetail = axios.get(`${baseUrl}/admin/view/rider/${params.id}?token=${token}`);
    let allLabs = axios.get(`${baseUrl}/admin/lab/all`, config)
    let allDeliveries = axios.get(`${baseUrl}/admin/view/dispatches?token=${token}`)

    await Promise.all([profileDetail, allLabs, allDeliveries])
      .then(data => {
        let profileResults = data[0].data;
        let allLabResults = data[1].data;
        let allDeliveries = data[2].data;

        this.setState({...this.state, ...profileResults, labs: allLabResults, deliveries: allDeliveries, isLoading: false})
      }).catch(error => { this.setState({isLoading: false})})
  }

  fetchLGAS = () => {
    fetch(`${baseUrl}/areas`, {
      method: 'GET'
    }).then(response => response.json())
      .then(responseJSON => {
        this.setState({ areas: responseJSON})
      })
  }

  editProfile = (e) => {
    this.setState({isLoading: true})
    let token = JSON.parse(localStorage.getItem('access_token'));

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    //Add Loader
    axios.put(`${baseUrl}/admin/update/rider/${this.state.id}`, this.state, config)
      .then(({data}) => {
        this.notifySuccess('Dispatch Rider Updated.')
        this.setState({isLoading: false})
      })
      .catch(error => {
        this.notifyError('An Error occurred.')
        this.setState({isLoading: false})
      })
    
  }

  blockUser = (e) => {
    this.setState({isLoading: true})
    let token = JSON.parse(localStorage.getItem('access_token'));

    axios.put(`${baseUrl}/rider/disable/rider/${this.state.id}?token=${token}`)
      .then(({ data }) => {
        this.notifySuccess('Dispatch Rider blocked')
        this.setState({isLoading: false})
      })
      .catch(error => {
        this.notifyError('Error disabling rider.')
        this.setState({isLoading: false})
      })
    

  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  setPage = (t) => (_) => {
    this.setState({
      currentPage: t
    }, () => console.dir(t))
  }

  notifyError = (message) => toast.error(message);
  notifySuccess = (message) => toast.success(message);

  navigate = (route) => {
    this.props.history.push(route)
  }

  render() {
    const {
      areas,
      currentPage,
      deliveries,
      labs,
      isLoading
    } = this.state;

    let areaOptions;
    if(areas.length > 0) {
      areaOptions = areas.map((areas, index) => 
        <option key={index}>{areas}</option>
      )
    }

    return (
      <main className="main-bg">
        <AuthNav page="dashboard"/>
        <div className="listings">
          <div className="top">
            <div className="flex" onClick={() => this.navigate('/dispatch')}>
              <GoBackIcon />
              <span style={{ color: '#276DF7', marginLeft: '5px', cursor: 'pointer' }}>Back</span>
            </div>
            <div className="title-search">
              <h4>{['Dispatch Profile', 'All Deliveries', 'All Laboratories'][currentPage]}</h4>
              {currentPage === 1 && <SearchFilter placeholder='Search for a delivery' />}
            </div>
          </div>
          <div className="content">
            <div>
          <div className="sideBarNav">
                    <ul>
                      <li className={(currentPage === 0 ? "focus" : "")}>
                        <div 
                          style={style.LinkStyle} 
                          onClick={this.setPage(0)} 
                          >Profile</div>
                      </li>

                      <li className={(currentPage === 1 ? "focus" : "")}>
                        <div 
                          style={style.LinkStyle} 
                          onClick={this.setPage(1)}>All Deliveries</div>
                      </li>

                      <li className={(currentPage === 2 ? "focus" : "")}>
                        <div 
                          style={style.LinkStyle} 
                          onClick={this.setPage(2)}>All Labs</div>
                      </li>
                    </ul>
                  </div>
                  </div>
                  <div>
                  {currentPage === 0 && (
                   <div className="dispatchProfile">
                   <div className="profileContainer">
                     <div className="profileHeader">
                       <div className="profileHeaderWrapper">
                         <div className="flex justifyBetween align-center">
                           <div className="flex align-center">
                            <div className="bigAvatarProfile flex justifyCenter align-center">
                              {this.state.firstName && <span>{this.state.firstName[0]}</span>}
                              {this.state.lastName && <span>{this.state.lastName[0]}</span>}
                            </div>
                            <p style={style.AvatarP}>{this.state.firstName} {" "} {this.state.lastName}</p>
                           </div>
                           
 
 
                           <div className="flex justifyAround">
                             <Button
                               text="Block User"
                               width='123px'
                               height='40px'
                               backgroundColor = 'rgba(254, 244, 244, 0.01)'
                               borderColor= '#DE1E1E'
                               textColor= '#DE1E1E'
                               onClick={this.blockUser}
                               />
 
                             <Button 
                               text='Edit Profile'
                               width='123px'
                               height='40px'
                               backgroundColor= '#276DF7'
                               textColor= '#FFF'
                               onClick={this.editProfile}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                   
                     <div className="profileFormSection">
                       <div className="form">
 
                       <div className="formGrid">
                         <div className="gridItem" style={{width: "60% !important"}}>
                           <div className="itemWrapper">
                             <div className="itemTitle">Dispatch Rider Info</div> 
                             <div className="itemSubTitle">Add a little info about the dispatch rider.</div> 
                           </div>  
                         </div>  
                         <div className="gridItem" style={{width: "40% !important"}}>
                           <div className="formControl">
                             <div className="formFull">
                               <div className={`formWrapper flexColumn formHalf mr-20 mr-10`}>
                                 <label htmlFor="firstName">First Name</label>
                                 <input 
                                   type="text"
                                   value={this.state.firstName}
                                   onChange={this.handleChange}
                                   name="firstName"/>
                               </div> 
 
                               <div className={`formWrapper flexColumn formHalf`}>
                                 <label htmlFor="lastName">Last Name</label>
                                 <input 
                                   type="text"
                                   value={this.state.lastName}
                                   onChange={this.handleChange}
                                   name="lastName" />
                               </div> 
                             </div>
 
                             <div className={`formWrapper flexColumn`}>
                               <label htmlFor="phone">Phone Number</label>
                               <input
                                 type="text"
                                 value={this.state.phone}
                                 onChange={this.handleChange}
                                 name="phone"/>
                             </div>
 
 
                             <div className={`formWrapper flexColumn`}>
                               <label htmlFor="email">Email Address</label>
                               <input 
                                 type="email"
                                 value={this.state.email}
                                 onChange={this.handleChange}
                                 name="email"  />
                             </div>
                           </div>
                         </div>   
                       
                       </div>
 
                       <div className="formGrid">
                         <div className="gridItem" style={{width: "60% !important"}}>
                           <div className="itemWrapper">
                             <div className="itemTitle">Rider’s Location</div> 
                             <div className="itemSubTitle">Where does the dispatch rider stay.</div> 
                           </div>  
                         </div>  
                         <div className="gridItem" style={{width: "40% !important"}}>
                           <div className="formControl">
                             <div className={`formWrapper flexColumn select p-0 mb-25`} style={{marginBottom: "20px !important"}}>
                               <label className="h-inherit" style={{padding: "3px 20px"}}>Rider's Area</label>
                               <select 
                                onChange={this.handleChange}
                                name="area" className="p-20 h-inherit" 
                                value={this.state.area}>
                                 {areaOptions}
                               </select>  
                             </div>
 
 
                             <div className={`formWrapper flexColumn`}>
                               <label htmlFor="address">Rider's Address</label>
                               <input 
                                 type="text"
                                 onChange={this.handleChange}
                                 value={this.state.address}
                                 name="address"  />
                             </div>
                           </div>
                         </div>   
                       </div>
                     </div>
                   </div>
                 </div>
               </div> 
                )}

               {currentPage === 1 && (
                  <div className="listingSection">
                    <AllDeliveries data={deliveries} /> 
                  </div>
                )}

                {currentPage === 2 && (
                  <AllDispatchLabsContainer labs={labs} />
                )}
                  </div>
          </div>
        </div>  
      <ToastContainer />
      {isLoading && <Loader />}
    </main>
    )
  }
}

export default withRouter(DetailProfile);