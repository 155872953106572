import React, { Component } from 'react';
import AuthNav from '../../nav/AuthNav';
import RidersListing from './Dispatch/Index';

class DashboardIndex extends Component {
  render() {
    return (
      <main className="main-bg">
        <AuthNav 
          page="dispatch"
        />

        <RidersListing />
        
      </main>
    )
  }
}


export default DashboardIndex