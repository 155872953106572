import React, { Component } from 'react';
import { Bar } from "react-chartjs-2";

const monthKeys = {
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec"
}

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

export default class BarChart extends Component {
    state = {
        data: {
            labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ],
            datasets: [
                {
                    label: "Negative",
                    backgroundColor: "rgba(51, 153, 51, 0.8)",
                    data: [65, 59, 80, 81, 56, 55, 200, 67, 98, 23, 12, 48]
                },

                {
                    label: "Positive",
                    backgroundColor: "rgba(229, 75, 75, 0.8)",
                    data: [45, 79, 50, 41, 16, 85, 20, 83, 35, 56, 82, 250]
                }
            ]
        }
    }

    componentDidMount(){
        // const { negativeData, positiveData } = this.props;
        // console.log({negativeData, positiveData});
    }

    generateGraphData = (negative, positive) => {
        let data = {};
        const dummyData = [0,0,0,0,0,0,0,0,0,0,0,0]
        const positiveGraph = positive && positive.graph;
        const negativeGraph = negative && negative.graph;
        const positiveData = positiveGraph ? positiveGraph.map(item => item.data) : dummyData;
        const negativeData = negativeGraph ? negativeGraph.map(item => item.data) : dummyData;


        let datasets = [
            {
                label: "Negative",
                backgroundColor: "rgba(51, 153, 51, 0.8)",
                data: positive && negativeData,
            },

            {
                label: "Positive",
                backgroundColor: "rgba(229, 75, 75, 0.8)",
                data: positive && positiveData,
            }
        ]

        let labels = positiveGraph ? positiveGraph.map(item => monthKeys[item.month]) : months;

        data = {
            labels,
            datasets
        }

        return data;
    }

    render() {
        const options = {
            responsive: true,
            legend: {
                display: false
            },
            type: "bar",
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    display: true,
                    barPercentage: 0.7,
                    categoryPercentage: 0.3,
                    gridLines: {
                        color: 'transparent',
                    }
                }],
            },
        }
        const { negativeData, positiveData } = this.props;
        const graphData = this.generateGraphData(negativeData, positiveData);
        return (
            <Bar
                data={graphData}
                width={"100%"}
                height={"286px"}
                options={options}
            />
        )
    }
}
