import React, { Component } from 'react'
import axios from 'axios'
import FormInput from '../../_common/form/FormInput'
import Button from '../../_common/Button'
import APP_URL from '../../../util/api';
import { withRouter, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../_common/loader/Loader';

const baseUrl = APP_URL;


class LoginForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showPassword: true,
      email: "",
      password: "",
      redirectTo: "",
      isLoading: false,
    }
  }

  notifyError = (msg) => toast.error(msg)

  login = async () => {
    this.setState({isLoading: true})
    await axios.post(`${baseUrl}/admin/login`, { email: this.state.email, password: this.state.password})
      .then(result => {
        let {data} = result;
        const appData = { role: data.role }
        localStorage.setItem('access_token', JSON.stringify(data.token));
        localStorage.setItem('app_data', JSON.stringify(appData))
        this.redirect(data.role);
      })
      .catch(error => {
        if(error.response && error.response.status === 400) {
          this.notifyError("Email and password don't match")
          this.setState({isLaoding: false})
          return 
        }
        this.notifyError('Something went wrong.')
        this.setState({isLaoding: false})
      })
    
  }

  redirect = (role) => {
    switch(role){
      case 'application_admin':
        this.setState({redirectTo: '/testers'})
        return
      case 'admin':
        this.setState({redirectTo: '/testers'})
        return
      case 'lab_personnel':
          this.setState({redirectTo: '/laboratory/kits/assigned'})
          return
      default:
        return
    }
  }

  togglePassword = (evt) => {
    this.setState({showPassword: !this.state.showPassword})
  }

  handleOnChange = (evt) => {
    this.setState({[evt.target.name] : evt.target.value});
  }

  render() {
    const appData = JSON.parse(localStorage.getItem('app_data'));
    const token = JSON.parse(localStorage.getItem('access_token'));
    const role = appData && appData.role;
    const path = role==='lab_personnel' ? '/laboratory' : '/testers'

    const { from } = this.props.location.state || { from: { pathname: path } };

    if (token) {
      return <Redirect to={from} />;
    }

    const { isLoading } = this.state;

    return (
      <div className="lbAdminForm">
        <h2>Welcome Back</h2>
        <p className="welcome">login into your admin dashboard.</p>

        <FormInput
          label="Email Address"
          type="email"
          name="email"
          placeHolder="johndoe@example.com"
          onChange={this.handleOnChange}
          style={{marginBottom: "25px"}}
          />

        <FormInput
          label="Password"
          type={(this.state.showPassword ? "password" : "text")}
          name="password"
          placeHolder="*********"
          onChange={this.handleOnChange}
          value={this.state.password}
          togglePassword={this.togglePassword}
          hasShowText={true}
          />
        <p className="forgot-password" onClick={() => this.props.history.push('/password/forgot')}>Forgot your password?</p>

        
        <div className="mb-65">
          <Button 
            text="Login"
            backgroundColor="#276DF7"
            isPrimary={true}
            onClick={this.login}
          />
        </div>
        <ToastContainer />
        {isLoading && <Loader />}
      </div>
      
    )
  }
}

export default withRouter(LoginForm)