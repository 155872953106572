import React, { Component } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import statuses from '../../../../util/statuses'


class AllTestKits extends Component {
  state = {
    redirectTo: '',
    kits: []
  }

  toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
  }



  navigate = (route) => {
    this.props.history.push({
      pathname: route
    })
  }
  
  render() {
    let {
      testKits
    } = this.props;
    return (
      <div className="table dispatchTable">
        <table>
          <thead>
            <tr>
              <th>Name & Kit Code</th>
              <th>Dispatch Rider's Name</th>
              <th>Delivery Date</th>
              <th>Status</th>
            </tr>
          </thead>
          
          <tbody>
            {testKits && testKits.map((item, index) => (
              <tr className="tr" key={index}>
                <td>
                  <div className="flex flexColumn name-email">
                    <div>{this.toTitleCase(item.case.firstName)} {" "} {this.toTitleCase(item.case.lastName)}</div>
                    <div>{item.kitCode}</div>
                  </div>    
                </td>
                <td>{item.personnel.firstName} {" "} {item.personnel.lastName}</td>
                <td>{moment(item.case.deliveryDate).format('LL')}</td>
                <td>
                  <button
                    className={'badges ' + item.status}
                    // onClick={e => this.navigate('/dispatch/profile')}
                  >
                      {statuses[item.status]}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>          
      </div>
            
    )
  }
}

export default withRouter(AllTestKits);