import React, { Component } from 'react'
import axios from 'axios'
import FormInput from '../../_common/form/FormInput'
import Button from '../../_common/Button'
import APP_URL from '../../../util/api';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const baseUrl = APP_URL;


class ForgetPasswordForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showPassword: true,
      email: "",
      password: "",
      redirectTo: "",
      resetSuccess: false,
    }
  }

  notifyError = (msg) => toast.error(msg)

  forgotPassword = () => {
    const token = JSON.parse(localStorage.getItem('access_token'));
    axios.post(`${baseUrl}/password/forget?email=${this.state.email}&token=${token}`)
      .then(({ data }) => {
        if(data.Success) {
          this.setState({resetSuccess: true})
        }
      })
      .catch(error => {
        let {
          data
        } = error.response;
        this.notifyError((data && data.Error) ? data.Error : "An error occurred")
      })
  }


  togglePassword = (evt) => {
    this.setState({showPassword: !this.state.showPassword})
  }

  handleOnChange = (evt) => {
    this.setState({[evt.target.name] : evt.target.value});
  }

  render() {
    let {
      resetSuccess
    } = this.state;
    return (
      <div className="lbAdminForm reset-form">
        <h2>Reset Password</h2>
        <p className="welcome">Enter your email to reset your password</p>

        <FormInput
          label="Email Address"
          type="email"
          name="email"
          placeHolder="johndoe@example.com"
          onChange={this.handleOnChange}
          style={{marginBottom: "25px"}}
          />

        {resetSuccess && (
          <div className="flex align-center plr47">
          <svg style={{marginRight: '11.36px'}} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99967 0.333496C3.31967 0.333496 0.333008 3.32016 0.333008 7.00016C0.333008 10.6802 3.31967 13.6668 6.99967 13.6668C10.6797 13.6668 13.6663 10.6802 13.6663 7.00016C13.6663 3.32016 10.6797 0.333496 6.99967 0.333496ZM5.19301 9.86016L2.79967 7.46683C2.53967 7.20683 2.53967 6.78683 2.79967 6.52683C3.05967 6.26683 3.47967 6.26683 3.73967 6.52683L5.66634 8.44683L10.253 3.86016C10.513 3.60016 10.933 3.60016 11.193 3.86016C11.453 4.12016 11.453 4.54016 11.193 4.80016L6.13301 9.86016C5.87967 10.1202 5.45301 10.1202 5.19301 9.86016Z" fill="#008000" fill-opacity="0.6"/>
          </svg>
          <p className="success-text">Password reset email sent successfully </p>
          </div>
        )}        
        

        
        <div className="mb-65">
          <Button 
            text="Reset Password"
            backgroundColor="#276DF7"
            isPrimary={true}
            onClick={this.forgotPassword}
          />
        </div>
        <ToastContainer />
      </div>
      
    )
  }
}

export default withRouter(ForgetPasswordForm)