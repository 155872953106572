import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="footerContainer">
        <p>Powered by {" "} </p>
        <img src="/assets/cchub_logo.png" alt="Co Creation Hub logo"/>
      </div>
    </footer>
  )
}

export default Footer