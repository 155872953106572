import React, { Component } from 'react'
import axios from 'axios'
import APP_URL from '../../../../util/api';
import colors from '../../../../util/colors';
import { search } from '../../../../util/requestCreator';
import Loader from '../../../_common/loader/Loader';


const baseUrl = APP_URL;

export default class SelectLab extends Component {
  state = {
    labs: [],
    currentFilter: 'all',
    isLoading: false,
  }

  getRandomColor = (colors) => {
    var i = Math.floor(Math.random() * colors.length);
    return colors[i];
  }

  componentDidMount() {
    this.loadLabs();
  }

  loadLabs = async () => {
    this.setState({ isLoading: true })
    let token = JSON.parse(localStorage.getItem('access_token'));

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    this.setState({isLoading: true})
    axios.get(`${baseUrl}/admin/lab/filter?filter=all`, config)
      .then(({data }) =>  {
        this.setState({labs: data, isLoading: false})
      })
      .catch(error => {
        console.dir(error.response)
        this.setState({isLoading: false})
      })
  }

  searchLab = async (query) => {
    this.setState({ isLoading: true })
    let token = JSON.parse(localStorage.getItem('access_token'));

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    this.setState({isLoading: true})
    const data = await search(`${baseUrl}/admin/lab/assigned/search?query=${query}`, config)
    this.setState({labs: data, isLoading: false})
  }

  filterLabs = (filter, location) => {
    let token = JSON.parse(localStorage.getItem('access_token'));
    let url = `${baseUrl}/admin/lab/filter`

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    if (!location) {
      this.setState({ isLoading: true })
      axios.get(url + '?filter=' + filter, config)
        .then(({ data }) => {
          this.setState({ labs: data, isLoading: false })
        })
        .catch(error => {
          this.setState({ isLoading: false })
          console.dir(error.response);
        })
    } else {
      this.setState({ isLoading: true })
      axios.get(`${baseUrl}/admin/lab/filter?location=${location}`, config)
        .then(({ data }) => {
          this.setState({ labs: data, isLoading: false })
        })
        .catch(error => {
          this.setState({ isLoading: false })
          console.dir(error.response);
        })
    }
  }

  setFilter = (filter, location) => {
    if (!location) {
      this.setState({ currentFilter: filter }, () => this.filterLabs(filter))
      return
    }

    this.setState({ currentFilter: 'location' }, () => this.filterLabs(filter, location))
  }

  selectLab = (id) => {
    this.props.assignToTester('labId', id)
    this.props.setPage(2)
  }

  render() {
    const {
      currentFilter,
      isLoading
    } = this.state;
    return (
      <React.Fragment>
         <div className="filter-section">
              <div className="filter-wrapper flex justifyBetween">
                <div className="filters">
                  <span className={'badge ' + (currentFilter === 'all' ? 'active' : '')} onClick={() => this.setFilter('all')}>All</span>
                  <span className={'badge ' + (currentFilter === 'location' ? 'active' : '')} onClick={(e) => this.setFilter('location', 'Yaba')}>Yaba Labs</span>
                  <span className={'badge ' + (currentFilter === 'unassigned' ? 'active' : '')} onClick={() => this.setFilter('unassigned')}>Unassigned Labs</span>
                  <span className={'badge '+ (currentFilter === 'assigned' ? 'active' : '')} onClick={() => this.setFilter('assigned')}>Assigned Labs</span>
                </div>

            <div className="search-filter flex">
              <div className="search-wrapper">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M5.31461 0C8.25163 0 10.6292 2.3749 10.6292 5.3086L10.6293 5.30862C10.6293 6.62152 10.154 7.8232 9.34269 8.75907L12 11.4134L11.4127 12L8.7554 9.3457C7.83206 10.1424 6.62905 10.6172 5.31461 10.6172C2.37759 10.6172 0 8.22805 0 5.3086C0 2.38915 2.37759 0 5.31461 0ZM0.839243 5.3086C0.839243 7.7676 2.85292 9.779 5.3147 9.779C7.79005 9.779 9.79017 7.7676 9.79017 5.3086C9.79017 2.8496 7.77649 0.8382 5.3147 0.8382C2.85292 0.8382 0.839243 2.8496 0.839243 5.3086Z" fill="#262626" fill-opacity="0.5" />
                </svg>
                <input type="text" placeholder="Search" onChange={e => this.searchLab(e.target.value)} />
              </div>
                <div className="sort-wrapper flex hide">
                  <div style={{marginRight: '5px'}}>Sort by</div>
                    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5 8.25H4C4.34375 8.25 4.625 7.96875 4.625 7.625C4.625 7.28125 4.34375 7 4 7H1.5C1.15625 7 0.875 7.28125 0.875 7.625C0.875 7.96875 1.15625 8.25 1.5 8.25ZM0.875 1.375C0.875 1.71875 1.15625 2 1.5 2H11.5C11.8438 2 12.125 1.71875 12.125 1.375C12.125 1.03125 11.8438 0.75 11.5 0.75H1.5C1.15625 0.75 0.875 1.03125 0.875 1.375ZM1.5 5.125H7.75C8.09375 5.125 8.375 4.84375 8.375 4.5C8.375 4.15625 8.09375 3.875 7.75 3.875H1.5C1.15625 3.875 0.875 4.15625 0.875 4.5C0.875 4.84375 1.15625 5.125 1.5 5.125Z" fill="#262626" fill-opacity="0.8"/>
                    </svg>
                  </div>
                </div>

              </div>
        </div>

        <div className="listing">
          {this.state.labs && this.state.labs.map((item, index) => (
            <div className="card" key={index}>
              <div className="card-header">
                <div className="card-header-wrapper flex">
                  <div className="card-avatar flex justifyCenter align-center" style={{ background: `${this.getRandomColor(colors)}` }}>EA</div>
                  <div className="flex flexColumn">
                    <h4>{item.name}</h4>
                    <p>{item.email}</p>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="card-item flex justifyBetween">
                  <div className="card_label">Lab Area</div>
                  <div className="item-value">{item.lab_area}</div>
                </div>

                <div className="card-item flex justifyBetween" style={{ minHeight: 'auto !important' }}>
                  <div className="flex align-center">
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.1" cx="17.5" cy="17.5" r="17.5" fill="#18131A" />
                      <path opacity="0.8" d="M11.25 24.75H24C24.4125 24.75 24.75 24.4125 24.75 24V23.25C24.75 22.8375 24.4125 22.5 24 22.5H11.25C10.8375 22.5 10.5 22.8375 10.5 23.25V24C10.5 24.4125 10.8375 24.75 11.25 24.75ZM24 15H11.25C10.8375 15 10.5 15.3375 10.5 15.75V20.25C10.5 20.6625 10.8375 21 11.25 21H24C24.4125 21 24.75 20.6625 24.75 20.25V15.75C24.75 15.3375 24.4125 15 24 15ZM10.5 12V12.75C10.5 13.1625 10.8375 13.5 11.25 13.5H24C24.4125 13.5 24.75 13.1625 24.75 12.75V12C24.75 11.5875 24.4125 11.25 24 11.25H11.25C10.8375 11.25 10.5 11.5875 10.5 12Z" fill="#18131A" />
                    </svg>

                    <p className="case-count">{item.cases.length} Samples</p>
                  </div>
                  <button className="view-btn" onClick={() => this.selectLab(item.id)}>Select</button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {isLoading && <Loader />}
      </React.Fragment>
    )
  }
}