import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Nav from '../nav/Nav';
import Footer from '../footer';
import PersonalDetails from './PersonalDetails';
import MoreDetails from './MoreDetails';
import LogSymptoms from './LogSymptoms';
import MedicalCondition from './MedicalCondition';
import DesktopSteps from './steps/DesktopSteps';
import MobileSteps from './steps/MobileSteps';
import REACT_APP_URL from '../../util/api';
import arrowSvg from '../../assets/svg/arrow.svg';
import Loader from '../_common/loader/Loader';



const baseUrl = REACT_APP_URL;

const initialState = {
  initialStep: 0,
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  gender: '',
  dob: '',
  countries_list: [],
  areas: [],
  nationality: '',
  marital_status: '',
  delivery_address: '',
  delivery_date: '',
  delivery_area: '',
  personalDetailsError: '',
  mass_gathering: false,
  contact_history: false,
  contact_confirmed: false,
  contact_travellers: false,
  travel_history: false,
  symptoms: [],
  conditions: [],
  isNoSymptoms: '',
  isNoCondition: '',
  isShortnessOfBreadth: '',
  isSoreThroat: '',
  isFever: '',
  isNasalCongestion: '',
  isDryCough: '',
  isRunningNose: '',
  isDiarrhoea: '',
  isAbdominalPain: '',
  preExistingCondition: '',
  pregnancy: '',
  obesity: '',
  heartDisease: '',
  asthmaWithMedication: '',
  chronicLungDisease: '',
  chronicLiverDisease: '',
  chronicHematologicalDisease: '',
  organRecipient: '',
  dobFieldFocused: false,
  deliveryDateFocused: false,
  isLoading: false,
}

export default class Form extends Component {
  state = initialState; 

  handleChange = (evt) => {
    this.setState({[evt.target.name]: evt.target.value})
  }

  handleFocus = (field) => (evt) => {
    if(field === 'dob') {
      this.setState({dobFieldFocused: true})
    } else if(field === "delivery_date") {
      this.setState({deliveryDateFocused: true})
    }
  }

  handleBlurredInput = (field) => (evt) => {
    if(field === 'dob') {
      this.setState({dobFieldFocused: false})
    } else if(field === "delivery_date") {
      this.setState({deliveryDateFocused: false})
    }
  }

  handleOtherSymptoms = () => {
    const { symptoms, other_symptoms } = this.state;
    if (other_symptoms.length > 0) {
        const otherSymptoms = this.stringToArray(other_symptoms);
        return [...symptoms, ...otherSymptoms]
    }
    return [...symptoms]
}

  handleSymptoms = (symptom) => {
    const { symptoms } = this.state;
    const exists = symptoms.includes(symptom);
    if (exists) {
      const index = symptoms.indexOf(symptom);
      const newSymptoms = symptoms;
      newSymptoms.splice(index, 1);
      this.setState({
        symptoms: [...newSymptoms],
        isNoSymptoms: symptom === "No Symptoms" ? false : this.state.isNoSymptoms
      })
    } else {
      if (symptom === "No Symptoms") {
        this.setState({
          symptoms: [symptom],
          isNoSymptoms: true
        })
      } else {
        this.setState({
          symptoms: [...symptoms, symptom]
        })
      }
    }
  }

handleSymptomsStyle = (symptom) => {
    const { symptoms } = this.state;
    const exists = symptoms.includes(symptom);
    return exists
}


handleOtherConditions = () => {
    const { conditions, other_conditions } = this.state;
    if (other_conditions.length > 0) {
        const otherConditions = this.stringToArray(other_conditions);
        return [...conditions, ...otherConditions]
    }
    return [...conditions]
}

  handleConditions = (condition) => {
    const { conditions } = this.state;
    const exists = conditions.includes(condition);
    if (exists) {
      const index = conditions.indexOf(condition);
      const newConditions = conditions;
      newConditions.splice(index, 1);
      this.setState({
        conditions: [...newConditions],
        isNoCondition: condition === "No pre-existing condition" ? false : this.state.isNoCondition
      })
    } else {
      if (condition === "No pre-existing condition") {
        this.setState({
          conditions: [condition],
          isNoCondition: true
        })
      } else {
        this.setState({
          conditions: [...conditions, condition]
        })
      }
    }
  }

handleConditionsStyle = (condition) => {
    const { conditions } = this.state;
    const exists = conditions.includes(condition);
    return exists
}

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

  validatePersonalDetails = () => {
    let {
      first_name,
      last_name,
      email,
      phone,
      gender,
      dob,
      marital_status,
      nationality,
      delivery_address,
      delivery_date
    } = this.state
    if(
      !first_name || !last_name || 
      !gender || !phone || 
      !email || !dob ||
      !marital_status || !nationality ||
      !delivery_address || !delivery_date) {
        this.setState({personalDetailsError: "Looks like you missed some fields"})
        return 
      }
      this.setState({
        initialStep: this.state.initialStep + 1,
        personalDetailsError: "",
      })
      this.scrollToTop();
  }

  validateMoreDetails = () => {
    let {
      mass_gathering,
      contact_history,
      contact_confirmed,
      contact_travellers,
      travel_history
    } = this.state;

    if(!mass_gathering || !contact_history || 
      !contact_confirmed || !contact_travellers ||
      !travel_history) {
        this.setState({moreDetailsError: "Looks like you missed some fields"})
        return 
      }
      this.setState({
        initialStep: this.state.initialStep + 1,
        moreDetailsError: ''
      })
      this.scrollToTop();
  }

  validateLogSymptoms = () => {
    if(this.state.symptoms.length === 0) {
      this.setState({logSymptomsError: "Please select symptoms. if none, pick No Symptoms."})
      return
    }
    this.setState({
      initialStep: this.state.initialStep + 1,
      logSymptomsError: ''
    });
    this.scrollToTop();
  }

  validateMedicalConditions = () => {
    if(this.state.conditions.length === 0) {
      this.setState({medicalConditionsError: "Please select pre-existing medical conditions. if none, pick No pre-existing condition."})
      return 
    }
    this.setState({
      medicalConditionsError: ''
    });
  }

  saveAndContinue = (evt) => {

    if(this.state.initialStep === 0) {
     this.validatePersonalDetails()
    }

    if(this.state.initialStep === 1) {
      this.validateMoreDetails()
    }

    if(this.state.initialStep === 2) {
      this.validateLogSymptoms()
    }
    
    if(this.state.initialStep > 2) {
      this.validateMedicalConditions()
      this.registerCase()
      return 
    }
  }

  componentDidMount() {
    this.fetchCountriesList();
    this.fetchLGAS()
  }

  cancel = (e) => {
    let { initialStep} = this.state;
    if(initialStep === 0) return;

    let currentStep = initialStep;
    currentStep--;
    this.setState({initialStep: currentStep })
    this.scrollToTop();
  }

  fetchLGAS = () => {
    fetch(`${baseUrl}/areas`, {
      method: 'GET'
    }).then(response => response.json())
      .then(responseJSON => {
        this.setState({ areas: responseJSON})
      })
  }

  fetchCountriesList = () => {
    fetch('https://restcountries.eu/rest/v2/all?fields=name', {
        method: 'GET'
    })
        .then(response => response.json())
        .then(responseJson => {
            this.setState({
                countries_list: responseJson
            })
        })
  }

  registerCase = () => {
    const {
      first_name,
      last_name,
      email,
      dob,
      gender,
      delivery_address,
      delivery_date,
      delivery_area,
      phone,
      marital_status,
      nationality,
      mass_gathering,
      contact_confirmed,
      contact_travellers,
      contact_history,
      travel_history,
      symptoms,
      conditions,
    } = this.state;

    const payload = {
      firstName: first_name,
      lastName: last_name,
      email,
      dob,
      gender,
      phone_no: phone,
      maritalStatus: marital_status,
      nationality,
      deliveryAddress: delivery_address,
      deliveryDate: delivery_date,
      deliveryArea: delivery_area,
      massGathering: Boolean(mass_gathering),
      contactHistory: Boolean(contact_history),
      contactConfirmedCase: Boolean(contact_confirmed),
      contactTravellers: Boolean(contact_travellers),
      travelHistory: Boolean(travel_history),
      symptoms,
      preExistingConditions: conditions,

    }

    this.setState({isLoading: true})

    fetch(`${baseUrl}/case`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      this.notifySuccess('Case successfully submitted')
      this.setState(initialState)
    })
    .catch((error) => {
      this.setState({isLoading: false})
      this.notifyError('An Error occurred while submitting case.')
      console.error('Error:', error);
    });

  }

  notifyError = (message) => toast.error(message);
  notifySuccess = (message) => toast.success(message);

  render() {
    let {
      initialStep,
      countries_list,
      areas,
      isLoading
    } = this.state;

    let countryOption;
    if (countries_list.length > 0) {
        countryOption = countries_list.map((country, index) =>
            <option key={index}>{country.name}</option>
        )
    }

    let areaOptions;
    if(areas.length > 0) {
      areaOptions = areas.map((areas, index) => 
        <option key={index}>{areas}</option>
      )
    }
    return (
      <main>
        <header>
          <Nav isTestersForm />
          <div className="formStepsSection">
            <DesktopSteps initialStep={initialStep}/>
            <MobileSteps initialStep={initialStep}/>
          </div>
        </header>

        <section className="formSection">
          <div className="container">
            {isLoading && <Loader />}
            {initialStep === 0 && (
              <PersonalDetails
                firstName={this.state.first_name}
                lastName={this.state.last_name}
                gender={this.state.gender}
                dob={this.state.dob}
                email={this.state.email}
                phone={this.state.phone}
                maritalStatus={this.state.marital_status}
                nationality={this.state.nationality}
                delivery_address={this.state.delivery_address}
                delivery_date={this.state.delivery_date}
                delivery_area={this.state.delivery_area}
                countryOptions={countryOption}
                areaOptions={areaOptions}
                personalDetailsError={this.state.personalDetailsError}
                dobFieldFocused={this.state.dobFieldFocused}
                deliveryDateFocused={this.state.deliveryDateFocused}
                onInputFocused={this.handleFocus}
                onInputBlurred={this.handleBlurredInput}
                handleChange={this.handleChange}
              />
            )}
            {initialStep === 1 && (
              <MoreDetails
                massGathering={this.state.mass_gathering}
                contactHistory={this.state.contact_history}
                contactWithConfirmedPatient={this.state.contact_confirmed}
                contactWithTraveller={this.state.contact_travellers}
                travelHistory={this.state.travel_history}
                moreDetailsError={this.state.moreDetailsError}
                handleChange={this.handleChange}
              />
            )}
            {initialStep === 2 && (
              <LogSymptoms 
                isNoSymptoms={this.state.isNoSymptoms}
                handleOptions={this.handleOptions}
                handleSymptoms={this.handleSymptoms}
                handleSymptomsStyle={this.handleSymptomsStyle}
              />
            )}
            {initialStep === 3 && (
              <MedicalCondition 
                isNoCondition={this.state.isNoCondition}
                handleOptions={this.handleOptions}
                handleConditionsStyle={this.handleConditionsStyle}
                handleConditions={this.handleConditions}
              /> 
            )}

            <div className="step-error">
              {this.state.personalDetailsError}
              {this.state.moreDetailsError}
              {this.state.logSymptomsError}
              {this.state.medicalConditionsError}
            </div>

            <div className="panelControls" style={{display: 'flex', justifyContent: 'space-between'}}>
              {
                initialStep > 0 &&
                <div className="cancel flex align-center justifyBetween" onClick={this.cancel}>
                  <span className="cancel-x">X</span>
                  <span className="back">Back</span>
                </div>
              }
              <div
                onClick={this.saveAndContinue} 
                className="saveContinue">
                {this.state.initialStep !== 3 ? "Save & Continue" : "Submit" }
                <img alt="" src={arrowSvg} style={{marginLeft: "10px"}} />
              </div>
            </div>
          </div>
        </section>

        <Footer />
        <ToastContainer />
      </main>
    )
  }
}