import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import AuthNav from '../../../nav/AuthNav';
import Button from "../../../_common/Button";
import cancelForm from "../../../../assets/svg/cancel-form.svg";
import Modal from '../../../modal/Modal';
import APP_URL from '../../../../util/api';
import Loader from '../../../_common/loader/Loader';

const baseUrl = APP_URL;

class UploadResult extends Component {
    state = {
        uploadModal: false,
        kitCode: '',
        result: '',
        isLoading: false,
    }

    componentDidMount(){
        const { match: { params: { id, code } } } = this.props;
        this.setState({ id, kitCode: code });
    }

    selectResult = (e) => {
        this.setState({
            result: e.target.value,
        })
    }

    openModal = () => {
        this.setState({
            uploadModal: true,
        })
    }

    closeModal = () => {
        this.setState({
            uploadModal: false,
        })
    }

    uploadResult = async (e) => {
        this.setState({isLoading: true})
        e.preventDefault();

        const { id, result } = this.state;

        let token = JSON.parse(localStorage.getItem('access_token'));

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        await axios.put(`${baseUrl}/lab/upload/${id}`, {result}, config)
            .then(({ data }) => {
                this.closeModal();
                this.goBack();
            })
            .catch(error => {
                console.dir(error)
            });
        this.setState({isLoading: false})
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/laboratory/kits/received'
        })
    }

    render() {
        const { kitCode, uploadModal, result, isLoading } = this.state;
        return (
            <main className="main-bg">
                <AuthNav
                    page='kits'
                    name="SYNLAB Nigeria"
                />
                <div className="upload-result">
                    <div className="top">
                        <svg
                          onClick={() => this.goBack()}
                          style={{marginRight: "15px"}} 
                          width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.0003 23.8332C18.9834 23.8332 23.8337 18.9829 23.8337 12.9998C23.8337 7.01675 18.9834 2.1665 13.0003 2.1665C7.01724 2.1665 2.16699 7.01675 2.16699 12.9998C2.16699 18.9829 7.01724 23.8332 13.0003 23.8332Z" stroke="#276DF7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M15.1663 8.6665L10.833 12.9998L15.1663 17.3332" stroke="#276DF7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <h4>Upload Test Result</h4>
                    </div>
                    <div className="content">
                        <h5>Result for kit code {kitCode} </h5>

                        <div className="select-result">
                            <p>Ensure you are uploading test result for the kit with the code {kitCode}</p>
                            <span>
                              <input type="radio" id="positive" name="result" value="positive" onChange={this.selectResult} />
                              <label htmlFor="positive">Positive</label>
                            </span>

                            <span>
                              <input type="radio" id="negative" name="result" value="negative" onChange={this.selectResult} />
                              <label htmlFor="negative">Negative</label>
                            </span>
                        </div>

                        <div className="button-div flex justifyBetween align-center">
                          <img src={cancelForm} alt="" onClick={() => this.props.history.goBack()}/>

                          <Button
                            text="Upload Result"
                            textColor="#FFF"
                            onClick={this.openModal}
                            backgroundColor="#276DF7"
                            width="194px"
                            disabled={!result}
                          />
                        </div>
                    </div>
                </div>
                <Modal show={uploadModal} handleClose={this.closeModal}>
                    <div className="modal-container">
                        <h4>Upload Result</h4>
                        <p>Are you sure you are uploading resulting for the kit with the code {kitCode}</p>

                        <div className="flex justifyBetween">
                            <div></div>

                            <div className="buttons-wrapper flex">
                                <p className="go-back" onClick={this.closeModal}>cancel</p>
                                <button className="modal-btn" onClick={this.uploadResult}>Yes, I am</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {isLoading && <Loader />}
            </main>
        )
    }
}

export default withRouter(UploadResult);
