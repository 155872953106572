import React, { Component } from 'react'
import axios from 'axios'
import FormInput from '../../_common/form/FormInput'
import Button from '../../_common/Button'
import APP_URL from '../../../util/api';
import { withRouter} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string'

const baseUrl = APP_URL;


class ResetPasswordForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showPassword: true,
      new_password: "",
      confirm_password: "",
      error: "",
      redirectTo: "",
      resetToken: ""
    }
  }

  componentDidMount() {
    let qs = queryString.parse(this.props.location.search)
    if(!qs.token) {
      return alert('Provide a reset token')
    }
    this.setState({resetToken: qs.token})

  }

  notifyError = (msg) => toast.error(msg)
  notifySuccess = (msg) => toast.success(msg)

  handleOnChange = (evt) => {
    this.setState({[evt.target.name] : evt.target.value});
  }

  resetPassword = (e) => {
    let {
      new_password,
      confirm_password,
      resetToken
    } = this.state;

    if (new_password !== confirm_password) {
      this.setState({error: 'Password does not match'})
      return 
    }

    axios.put(`${baseUrl}/password/reset?token=${resetToken}&newPassword=${new_password}&confirmPassword=${confirm_password}`)
      .then(({ data }) => {
        this.notifySuccess("Password reset successfull");
      })
      .catch(error => {
        let {
          data
        } = error.response;
        if(data && data.Error) {
          this.notifyError(data.Error)
          return 
        }
        this.notifyError("Error resetting password");
      })
  }

  render() {
    let {
      error,
      new_password,
      confirm_password
    } = this.state;
    return (
      <div className="lbAdminForm">
        <h2>Reset Password</h2>
        <p className="welcome">Set your new password</p>

        <FormInput
          label="New Password"
          type="password"
          name="new_password"
          placeHolder="*********"
          onChange={this.handleOnChange}
          style={{marginBottom: "25px"}}
          />

        <FormInput
          label="Confirm New Password"
          type="password"
          name="confirm_password"
          placeHolder="*********"
          onChange={this.handleOnChange}
          hasShowText={true}
          />
        
        {new_password && confirm_password && error && <p className="error">{error}</p>}
        <div className="mb-65">
          <Button 
            text="Reset Password"
            backgroundColor="#276DF7"
            isPrimary={true}
            onClick={this.resetPassword}
          />
        </div>
        <ToastContainer />
      </div>
      
    )
  }
}

export default withRouter(ResetPasswordForm)