import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import AuthNav from '../../../nav/AuthNav'
import AdminListing from './AdminListing'

class Admins extends Component {
  render() {
    return (
      <main className="main-bg">
        <AuthNav 
          page='admin'
        />
        <AdminListing />
      </main>
    )
  }
}

export default withRouter(Admins)