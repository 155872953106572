import React from 'react';

const Nav = ({isTestersForm}) => {
  return (
    <nav className={"nav " + (isTestersForm ? "testers-nav" : "")}> 
      <svg width="144" height="32" viewBox="0 0 144 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3841 0C11.8576 0 11.4315 0.426541 11.4315 0.952623V10.4789H0.952624C0.672605 10.4789 0.420494 10.5996 0.246529 10.7919C0.0930297 10.961 0 11.1854 0 11.4315V19.0525C0 19.5786 0.426076 20.0051 0.952624 20.0051H11.4315V31.0465C11.4315 31.3475 11.5673 31.6071 11.7748 31.7792C12.0194 31.9822 12.3627 32.0631 12.6986 31.9457L20.3195 29.2783C20.5344 29.2032 20.7093 29.0564 20.821 28.8701C20.9084 28.7252 20.9577 28.5564 20.9577 28.3792V20.0051H31.4366C31.9631 20.0051 32.3892 19.5786 32.3892 19.0525V11.4315C32.3892 10.9054 31.9631 10.4789 31.4366 10.4789H20.9577V0.952623C20.9577 0.426541 20.5316 0 20.0051 0H12.3841Z" fill="#B70303"/>
        <path d="M39.7798 6.70193H42.2633V25.3284H51.8426V27.6345H39.7798V6.70193Z" fill="#3B3B3B"/>
        <path d="M53.4687 13.7977H55.7749V27.6345H53.4687V13.7977ZM52.9366 8.38718C52.9366 7.93383 53.0942 7.53962 53.4096 7.20454C53.7447 6.86947 54.1488 6.70193 54.6218 6.70193C55.0949 6.70193 55.4891 6.86947 55.8044 7.20454C56.1395 7.53962 56.3071 7.93383 56.3071 8.38718C56.3071 8.87994 56.1494 9.284 55.834 9.59937C55.5186 9.91474 55.1146 10.0724 54.6218 10.0724C54.129 10.0724 53.725 9.91474 53.4096 9.59937C53.0942 9.284 52.9366 8.87994 52.9366 8.38718Z" fill="#3B3B3B"/>
        <path d="M60.6602 15.7491H57.6445V13.7977H60.6602V10.6933C60.6602 9.84575 60.739 9.07704 60.8967 8.38718C61.0544 7.6776 61.3205 7.06657 61.695 6.5541C62.0892 6.04162 62.6017 5.64741 63.2324 5.37146C63.8631 5.07581 64.6417 4.92798 65.5681 4.92798C65.8637 4.92798 66.1791 4.95754 66.5142 5.01667C66.8493 5.07581 67.1252 5.1645 67.342 5.28277L67.0168 7.35237C66.4649 7.15527 65.9426 7.05671 65.4498 7.05671C64.8585 7.05671 64.3953 7.16512 64.0602 7.38194C63.7252 7.57904 63.4689 7.86485 63.2915 8.23935C63.1338 8.59414 63.0353 9.02777 62.9959 9.54024C62.9762 10.0527 62.9663 10.6046 62.9663 11.1959V13.7977H66.3368V15.7491H62.9663V27.6345H60.6602V15.7491Z" fill="#3B3B3B"/>
        <path d="M80.275 25.1214C79.4471 26.1858 78.5405 26.9348 77.5549 27.3684C76.5694 27.7823 75.4262 27.9893 74.1253 27.9893C73.0412 27.9893 72.0656 27.802 71.1983 27.4275C70.331 27.0333 69.5919 26.511 68.9809 25.8606C68.3895 25.2101 67.9263 24.4414 67.5913 23.5544C67.2759 22.6675 67.1182 21.7213 67.1182 20.7161C67.1182 19.6517 67.2956 18.6761 67.6504 17.7891C68.0052 16.8824 68.4979 16.1137 69.1287 15.483C69.7594 14.8325 70.5084 14.3299 71.3757 13.9751C72.2429 13.6203 73.1891 13.4429 74.214 13.4429C75.1798 13.4429 76.0668 13.6105 76.8749 13.9455C77.6831 14.2609 78.3729 14.734 78.9445 15.3647C79.5358 15.9757 79.9892 16.7346 80.3046 17.6413C80.6199 18.5282 80.7776 19.5433 80.7776 20.6865V21.4257H69.6017C69.6412 22.017 69.7791 22.5886 70.0157 23.1405C70.2719 23.6727 70.5971 24.1457 70.9913 24.5597C71.3855 24.9539 71.8487 25.2692 72.3809 25.5058C72.9131 25.7423 73.4946 25.8606 74.1253 25.8606C75.1305 25.8606 75.9781 25.6832 76.668 25.3284C77.3578 24.9736 77.9787 24.4513 78.5306 23.7614L80.275 25.1214ZM78.2941 19.4743C78.2547 18.2917 77.8703 17.3456 77.141 16.636C76.4117 15.9265 75.4065 15.5717 74.1253 15.5717C72.8441 15.5717 71.8093 15.9265 71.0209 16.636C70.2325 17.3456 69.7594 18.2917 69.6017 19.4743H78.2941Z" fill="#3B3B3B"/>
        <path d="M83.8721 6.70193H90.2583C91.1256 6.70193 91.9731 6.79062 92.8009 6.96802C93.6288 7.14541 94.3679 7.44107 95.0184 7.85499C95.6688 8.2492 96.1912 8.77153 96.5854 9.42198C96.9993 10.0724 97.2063 10.8806 97.2063 11.8464C97.2063 13.0684 96.8712 14.0835 96.201 14.8916C95.5506 15.6801 94.6636 16.2418 93.5401 16.5769V16.636C94.2103 16.6952 94.8311 16.8726 95.4027 17.1682C95.9941 17.4442 96.4967 17.8088 96.9106 18.2622C97.3442 18.7155 97.6793 19.2477 97.9158 19.8587C98.1524 20.45 98.2706 21.0906 98.2706 21.7805C98.2706 22.9434 98.0242 23.8994 97.5315 24.6484C97.0584 25.3974 96.4277 25.9985 95.6393 26.4519C94.8706 26.8855 93.9934 27.191 93.0079 27.3684C92.0421 27.5458 91.0763 27.6345 90.1105 27.6345H83.8721V6.70193ZM86.3556 15.5717H89.7852C90.3766 15.5717 90.9482 15.5322 91.5001 15.4534C92.0717 15.3746 92.5841 15.2169 93.0375 14.9803C93.4908 14.7241 93.8555 14.3792 94.1314 13.9455C94.4074 13.4922 94.5453 12.9009 94.5453 12.1716C94.5453 11.5211 94.4172 10.9988 94.161 10.6046C93.9047 10.1907 93.5598 9.86546 93.1262 9.62894C92.7123 9.39241 92.2293 9.23473 91.6775 9.15589C91.1453 9.05733 90.6032 9.00806 90.0513 9.00806H86.3556V15.5717ZM86.3556 25.3284H90.5539C91.165 25.3284 91.7661 25.2692 92.3575 25.151C92.9685 25.013 93.5105 24.806 93.9836 24.5301C94.4763 24.2344 94.8706 23.8599 95.1662 23.4066C95.4619 22.9335 95.6097 22.3619 95.6097 21.6918C95.6097 20.9625 95.4619 20.3515 95.1662 19.8587C94.8903 19.3659 94.5158 18.9717 94.0427 18.6761C93.5894 18.3804 93.0572 18.1735 92.4462 18.0552C91.8548 17.9369 91.2438 17.8778 90.6131 17.8778H86.3556V25.3284Z" fill="#3B3B3B"/>
        <path d="M101.017 15.5717C101.726 14.8424 102.564 14.3102 103.53 13.9751C104.515 13.6203 105.511 13.4429 106.516 13.4429C108.566 13.4429 110.044 13.9258 110.951 14.8916C111.858 15.8575 112.311 17.3259 112.311 19.297V25.2397C112.311 25.6339 112.331 26.0478 112.37 26.4814C112.409 26.8954 112.459 27.2797 112.518 27.6345H110.3C110.222 27.3191 110.172 26.9742 110.153 26.5997C110.153 26.2055 110.153 25.8606 110.153 25.5649H110.093C109.64 26.2745 109.029 26.8559 108.26 27.3093C107.511 27.7626 106.605 27.9893 105.54 27.9893C104.831 27.9893 104.161 27.9006 103.53 27.7232C102.919 27.5458 102.377 27.2896 101.904 26.9545C101.45 26.5997 101.086 26.1661 100.81 25.6536C100.534 25.1411 100.396 24.5498 100.396 23.8797C100.396 22.7364 100.692 21.8396 101.283 21.1892C101.874 20.5387 102.603 20.0558 103.471 19.7404C104.358 19.4251 105.304 19.228 106.309 19.1491C107.334 19.0703 108.27 19.0309 109.118 19.0309H110.005V18.6169C110.005 17.6117 109.699 16.8528 109.088 16.3404C108.497 15.8279 107.659 15.5717 106.575 15.5717C105.826 15.5717 105.087 15.6998 104.358 15.956C103.648 16.1925 103.017 16.567 102.465 17.0795L101.017 15.5717ZM107.699 20.9822C106.22 20.9822 105.048 21.1892 104.18 21.6031C103.313 22.017 102.879 22.697 102.879 23.6431C102.879 24.5104 103.165 25.1313 103.737 25.5058C104.328 25.8606 105.057 26.038 105.925 26.038C106.595 26.038 107.176 25.9295 107.669 25.7127C108.182 25.4762 108.605 25.1707 108.94 24.7962C109.275 24.4217 109.532 23.9881 109.709 23.4953C109.887 22.9828 109.985 22.4408 110.005 21.8692V20.9822H107.699Z" fill="#3B3B3B"/>
        <path d="M115.639 17.0795C115.639 16.4488 115.619 15.8575 115.58 15.3056C115.54 14.7537 115.52 14.2511 115.52 13.7977H117.708C117.708 14.1722 117.718 14.5467 117.738 14.9212C117.758 15.2957 117.767 15.6801 117.767 16.0743H117.827C117.984 15.7392 118.201 15.414 118.477 15.0986C118.773 14.7832 119.118 14.5073 119.512 14.2708C119.906 14.0145 120.349 13.8174 120.842 13.6795C121.335 13.5218 121.867 13.4429 122.439 13.4429C123.345 13.4429 124.124 13.5809 124.775 13.8568C125.445 14.1328 125.997 14.5171 126.43 15.0099C126.864 15.5027 127.179 16.1038 127.376 16.8134C127.593 17.5033 127.702 18.2621 127.702 19.09V27.6345H125.395V19.3265C125.395 18.1636 125.139 17.2471 124.627 16.5769C124.114 15.9067 123.316 15.5717 122.232 15.5717C121.483 15.5717 120.832 15.6998 120.281 15.956C119.748 16.2123 119.305 16.5769 118.95 17.05C118.615 17.523 118.359 18.0848 118.181 18.7352C118.024 19.3856 117.945 20.1051 117.945 20.8935V27.6345H115.639V17.0795Z" fill="#3B3B3B"/>
        <path d="M131.18 5.28277H133.486V19.8883L139.784 13.7977H143.066L136.384 20.0952L143.746 27.6345H140.346L133.486 20.3613V27.6345H131.18V5.28277Z" fill="#3B3B3B"/>
        <path d="M56.8903 7.92378C56.8903 9.28451 55.7872 10.3876 54.4265 10.3876C53.0657 10.3876 51.9626 9.28451 51.9626 7.92378C51.9626 6.56305 53.0657 5.45996 54.4265 5.45996C55.7872 5.45996 56.8903 6.56305 56.8903 7.92378Z" fill="#3B3B3B"/>
      </svg>
    </nav>
  )
}

export default Nav;