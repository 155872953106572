import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '../../../_common/Button'
import { scrollToTop } from '../../../../util/helper';


class Laboratories extends Component {
  
  navigate = (route) => {
    this.props.history.push({
      pathname: route
    })
    scrollToTop();
  }
  
  render() {
    return (
      <section className="lbDispatchSection">
          <div className="container">
            <div className="listingSection">
              <div className="tableFilter">
                <div className="flex justifyBetween">
                  <h4>Laboratories</h4>

                  <div className="filter flex align-center" >
                    <div className="searchInputWrapper flex align-center">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.31461 0C8.25163 0 10.6292 2.3749 10.6292 5.3086L10.6293 5.30862C10.6293 6.62152 10.154 7.8232 9.34269 8.75907L12 11.4134L11.4127 12L8.7554 9.3457C7.83206 10.1424 6.62905 10.6172 5.31461 10.6172C2.37759 10.6172 0 8.22805 0 5.3086C0 2.38915 2.37759 0 5.31461 0ZM0.839243 5.3086C0.839243 7.7676 2.85292 9.779 5.3147 9.779C7.79005 9.779 9.79017 7.7676 9.79017 5.3086C9.79017 2.8496 7.77649 0.8382 5.3147 0.8382C2.85292 0.8382 0.839243 2.8496 0.839243 5.3086Z" fill="#262626" fillOpacity="0.5"/>
                      </svg>
                      <input type='text' placeholder='Search for laboratories' onChange={(e) => this.props.handleSearch(e.target.value)} />
                    </div>
                    <Button
                      text='Add Labs'
                      textColor="#FFF"
                      backgroundColor="#276DF7"
                      isPrimary={false}
                      fontSize="14px"
                      height="40px"
                      width="158px"
                      className="m-0"
                      onClick={() => this.navigate('/lab/add')}
                    />
                  </div>
                </div>
              </div>
            
              <div className="table dispatchTable">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Lab Name</th>
                      <th>Contact Person</th>
                      <th>Phone Number</th>
                      <th>Location</th>
                      <th></th>
                    </tr> 
                  </thead>
                  
                  <tbody>
                    {
                    this.props.data && this.props.data.map((item, index) => { 
                      const { name, lab_address, users } = item;
                      let phone_no = (item.users && item.users.length > 0 ) ? item.users[0].phone : ''
                      return(
                      <tr className="tr" key={index}>
                        <td className="avatar_td">
                          <div className="avatar" style={{marginLeft: "10px"}}>
                            {name && <span>{name[0]}</span>}
                          </div>
                        </td>
                        <td>
                          {name}
                        </td>
                        <td>{users[0] && users[0].firstName} {users[0] && users[0].lastName}</td>{/** TODO: Change to Contact Person first and last name */}
                        <td>{phone_no}</td>
                        <td>{lab_address}</td>
                        <td>
                          <button className='view-btn' onClick={() => this.navigate(`/labs/profile/${item.id}`)}>View</button>
                        </td>
                      </tr>
                    )})
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
    )
  }
}

export default withRouter(Laboratories)