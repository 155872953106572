import React from 'react'


const NavProfile = ({ name, imageUrl, logOut, gotoProfile, toggleProfileMenu, showProfileMenu }) => (
  <div className="nav-profile-container" onClick={toggleProfileMenu}>
    <div className="nav-profile flex align-center">
      <img className="profile-avatar" src={imageUrl} alt="" />
      <p className="profile-name">{name}</p>
      <svg 
        width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.46473 4.46314L5.50008 4.49849L5.53544 4.46314L9.41883 0.579744L10.4675 1.62845L5.50008 6.59591L0.532625 1.62845L1.58133 0.579744L5.46473 4.46314Z" fill="black" stroke="#FDF7F7" strokeWidth="0.1" />
      </svg>
    </div>

    <div className={"profile-menu " + (showProfileMenu ? 'show': 'hide')}>
      <div className="menu-wrapper">
        <div className="menu-list">
          <div className="flex align-center list-item">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.6" d="M9.99984 1.66699C5.39984 1.66699 1.6665 5.40033 1.6665 10.0003C1.6665 14.6003 5.39984 18.3337 9.99984 18.3337C14.5998 18.3337 18.3332 14.6003 18.3332 10.0003C18.3332 5.40033 14.5998 1.66699 9.99984 1.66699ZM9.99984 4.16699C11.3832 4.16699 12.4998 5.28366 12.4998 6.66699C12.4998 8.05033 11.3832 9.16699 9.99984 9.16699C8.6165 9.16699 7.49984 8.05033 7.49984 6.66699C7.49984 5.28366 8.6165 4.16699 9.99984 4.16699ZM9.99984 16.0003C7.9165 16.0003 6.07484 14.9337 4.99984 13.317C5.02484 11.6587 8.33317 10.7503 9.99984 10.7503C11.6582 10.7503 14.9748 11.6587 14.9998 13.317C13.9248 14.9337 12.0832 16.0003 9.99984 16.0003Z" fill="#827F98"/>
            </svg>
            <p onClick={gotoProfile}>Profile</p>
          </div>

          <div className="flex align-center list-item">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.6" d="M10.0001 2.5C9.54173 2.5 9.16673 2.875 9.16673 3.33333V10C9.16673 10.4583 9.54173 10.8333 10.0001 10.8333C10.4584 10.8333 10.8334 10.4583 10.8334 10V3.33333C10.8334 2.875 10.4584 2.5 10.0001 2.5ZM14.2834 4.88333C13.9584 5.20833 13.9667 5.71667 14.2751 6.04167C15.2167 7.04167 15.8001 8.375 15.8334 9.85C15.9084 13.0417 13.2667 15.7917 10.0751 15.825C6.81673 15.875 4.16673 13.25 4.16673 10C4.16673 8.46667 4.7584 7.075 5.72506 6.03333C6.0334 5.70833 6.0334 5.2 5.71673 4.88333C5.3834 4.55 4.84173 4.55833 4.52506 4.9C3.31673 6.18333 2.5584 7.89167 2.50006 9.78333C2.3834 13.85 5.69173 17.3667 9.7584 17.4917C14.0084 17.625 17.5001 14.2167 17.5001 9.99167C17.5001 8.01667 16.7334 6.23333 15.4834 4.9C15.1667 4.55833 14.6167 4.55 14.2834 4.88333Z" fill="#827F98"/>
            </svg>
            <p onClick={logOut}>Log Out</p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default NavProfile