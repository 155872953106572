import React, { Component } from 'react';
import moment from 'moment';

export default class CompletedTests extends Component {
    render() {
        const { data, searchCode } = this.props;
        return (
            <React.Fragment>
                <table>
                    <thead>
                        <tr>
                            <th>Kit Code</th>
                            <th>Delivery Date</th>
                            <th>Dispatch Rider</th>
                            <th>Date Uploaded</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((item) => {
                                const { id, kitCode, personnel, case: tester } = item;
                                const { result, deliveryDate, resultUploadedAt } = tester;
                                const { firstName, lastName } = personnel;
                                const name = `${firstName} ${lastName}`;
                            return(
                            <tr className="tr" key={id}>
                                <td>{kitCode}</td>
                                <td>{moment(deliveryDate).format('MMM DD, YYYY')}</td>
                                <td>{name}</td>
                                <td>{moment(resultUploadedAt).format('MMM DD, YYYY')}</td>
                                <td>
                                    <div className={`status ${result}`}>{result}</div>
                                </td>
                            </tr>
                        )})}
                        {
                            data && data.length === 0 && !searchCode &&
                            <tr className="tr">
                                <td colSpan="5" style={{ textAlign: 'center' }}>There are no completed tests at the moment.</td>
                            </tr>
                        }
                        {
                            data && data.length === 0 && !!searchCode &&
                            <tr className="tr">
                                <td colSpan="5" style={{ textAlign: 'center' }}>There are no completed tests with the code {searchCode}.</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}
