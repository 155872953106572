import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import AuthNav from '../../../nav/AuthNav'
import AssignedTestersTable from '../../../_common/table/AssignedTesters'
import TestersTable from '../../../_common/table/TestersTable'
import FailedDeliveriesTable from '../../../_common/table/FailedDeliveriesTable'
import TestResultTable from '../../../_common/table/AllTestResultsTable'
import REACT_APP_URL from '../../../../util/api'
import Loader from '../../../_common/loader/Loader'
import { search } from '../../../../util/requestCreator'

const baseUrl = REACT_APP_URL

const style = {
  LinkStyle: {
    textDecoration: "none",
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#18131A",
  },
  AvatarP: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '25px',
    marginLeft: '8px'
  }
}

class TesterListing extends Component {
  state = {
    currentPage: 'unassigned',
    id: '',
    token: '',
  }

  componentDidMount() {
    const { match: { params: { page } } } = this.props;
    this.setState({ currentPage: page }, () => this.loadLists());
  }

  notifyError = (msg) => toast.error(msg);
  notifySuccess = (msg) => toast.success(msg);

  loadLists = () => {
    let token = JSON.parse(localStorage.getItem('access_token'));
    let config = {
      headers : {
        Authorization: `Bearer ${token}`
      }
    }

    const { currentPage } = this.state;

    const queries = {
      unassigned: 'filter/cases?query=unassigned&',
      assigned: 'filter/cases?query=assigned&',
      failed: 'view/failed/dispatches?',
      results: 'view/dispatches?testResults=true'
    };

    this.setState({
      isLoading: true,
    })
    axios.get(`${baseUrl}/admin/${queries[currentPage]}`, config)
      .then(({ data }) => {
        console.log(data)
        this.setState({ data, isLoading: false })
      })
      .catch(error => {
        this.setState({ isLoading: false })
      })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  setPage = (currentPage) => {
    this.navigateScreens(currentPage);
    this.setState({
      currentPage,
      data: null
    }, () => {
      this.loadLists()
    })
  }

  sendTestResult = (id) => {
    let token = JSON.parse(localStorage.getItem('access_token'));
    axios.post(`${baseUrl}/admin/testers/result/${id}/send?token=${token}`)
      .then(({ data }) => {
        this.notifySuccess("Test result sent.")
      })
      .catch(error => {
        this.notifyError("Error sending test result.")
      })
  }

  navigateScreens = (page) => {
    this.props.history.push({
      pathname: `/testers/${page}`
    })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  handleSearch = async (query) => {
    let {
      currentPage
    } = this.state;

    let token = JSON.parse(localStorage.getItem('access_token'));
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    let data;

    switch(currentPage) {
      case "failed":
        data = await search(`${baseUrl}/admin/view/failed/dispatches?query=${query}`, config)
        break;
      case "assigned":
      case "unassigned":
        data = await search(`${baseUrl}/admin/filter/cases?query=${currentPage}&search=${query}`, config)
        break;
      case "results":
        data = await search(`${baseUrl}/admin/view/dispatches?testResults=true&search=${query}`, config)
        break;
      default:
          break;
    }

    this.setState({data: data || [], isLoading: false})
  }

  generatePageObject = () => {
    const { currentPage } = this.state;
    switch (currentPage) {
      case 'unassigned':
        return {
          title: 'Unassigned Testers',
          placeholder: 'Search for a tester'
        }
      case 'assigned':
        return {
          title: 'All Assigned Testers',
          placeholder: 'Search for a tester'
        }
      case 'failed':
        return {
          title: 'All Failed Deliveries',
          placeholder: 'Search for a delivery'
        }
      case 'results':
        return {
          title: 'All Test Result',
          placeholder: 'Search for a result'
        }
      default:
        return
    }
  }

  render() {
    const { currentPage, data } = this.state;
    const pageObject = this.generatePageObject();
    return (
      <main className="main-bg">
        <AuthNav page="testers" />
        <div className="listings">
          <div className="top">
            <div className="spacer"></div>
            <div className="title-search">
              <h4>{pageObject.title}</h4>

              <div className="filter">
                <div className="searchInputWrapper flex align-center">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.31461 0C8.25163 0 10.6292 2.3749 10.6292 5.3086L10.6293 5.30862C10.6293 6.62152 10.154 7.8232 9.34269 8.75907L12 11.4134L11.4127 12L8.7554 9.3457C7.83206 10.1424 6.62905 10.6172 5.31461 10.6172C2.37759 10.6172 0 8.22805 0 5.3086C0 2.38915 2.37759 0 5.31461 0ZM0.839243 5.3086C0.839243 7.7676 2.85292 9.779 5.3147 9.779C7.79005 9.779 9.79017 7.7676 9.79017 5.3086C9.79017 2.8496 7.77649 0.8382 5.3147 0.8382C2.85292 0.8382 0.839243 2.8496 0.839243 5.3086Z"
                      fill="#262626"
                      fillOpacity="0.5"
                    />
                  </svg>
                  <input type="text" placeholder={pageObject.placeholder} onChange={(e) => this.handleSearch(e.target.value)} />
                </div>
              </div> 
            </div>
          </div>
          <div className="content">
            <div>
              <div className="sideBarNav">
                <ul>
                  <li
                    className={
                      this.state.currentPage === "unassigned" ? "focus" : ""
                    }
                    onClick={() => this.setPage("unassigned")}
                  >
                    <div style={style.LinkStyle}>Unassigned</div>
                  </li>

                  <li
                    className={
                      this.state.currentPage === "assigned" ? "focus" : ""
                    }
                    onClick={() => this.setPage("assigned")}
                  >
                    <div style={style.LinkStyle}>All Assigned</div>
                  </li>

                  <li
                    className={
                      this.state.currentPage === "failed" ? "focus" : ""
                    }
                    onClick={() => this.setPage("failed")}
                  >
                    <div style={style.LinkStyle}>Failed Deliveries</div>
                  </li>

                  <li
                    className={
                      this.state.currentPage === "results" ? "focus" : ""
                    }
                    onClick={() => this.setPage("results")}
                  >
                    <div style={style.LinkStyle}>Test Results</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="table dispatchTable">
              {this.state.isLoading && <Loader />}

              {currentPage === "unassigned" && <TestersTable data={data} />}

              {currentPage === "assigned" && (
                <AssignedTestersTable data={data} />
              )}

              {currentPage === "failed" && (
                <FailedDeliveriesTable data={data} fetchCases={this.loadLists} />
              )}

              {currentPage === "results" && <TestResultTable data={data} sendResults={this.sendTestResult} />}
            </div>
          </div>
        </div>
        <ToastContainer />
      </main>
    );
  }
}

export default withRouter(TesterListing)