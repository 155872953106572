import React, { Component } from "react";
import check from "../../assets/svg/checkbox-check.svg";
import unchecked from "../../assets/svg/checkbox-unchecked.svg";

export default class LogSymptoms extends Component {
  render() {
    let { isNoSymptoms, handleSymptoms, handleSymptomsStyle } = this.props;
    return (
      <div className="form formLogSymptoms">
        <div className="formGrid">
          <div className="gridItem">
            <div className="itemWrapper">
              <div className="itemTitle">Select symptoms</div>
              <div className="itemSubTitle">
                Kindly select the symptoms you’ve been having.
              </div>
            </div>
          </div>
          <div className="gridItem">
            <div className="formControl">
              <div
                onClick={() => handleSymptoms("No Symptoms")}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleSymptomsStyle("No Symptoms") ? "itemSelected" : "")
                }
              >
                <p>No Symptoms</p>
                {handleSymptomsStyle("No Symptoms") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoSymptoms && handleSymptoms("Shortness of Breadth");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleSymptomsStyle("Shortness of Breadth")
                    ? "itemSelected"
                    : "") +
                  (isNoSymptoms ? "itemDisabled" : "")
                }
              >
                <p>Shortness of Breadth</p>
                {handleSymptomsStyle("Shortness of Breadth") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoSymptoms && handleSymptoms("Sore throat");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleSymptomsStyle("Sore throat") ? "itemSelected" : "") +
                  (isNoSymptoms ? "itemDisabled" : "")
                }
              >
                <p>Sore throat</p>
                {handleSymptomsStyle("Sore throat") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoSymptoms && handleSymptoms("Fever");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleSymptomsStyle("Fever") ? "itemSelected" : "") +
                  (isNoSymptoms ? "itemDisabled" : "")
                }
              >
                <p>Fever</p>
                {handleSymptomsStyle("Fever") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoSymptoms && handleSymptoms("Nasal Congestion");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleSymptomsStyle("Nasal Congestion")
                    ? "itemSelected"
                    : "") +
                  (isNoSymptoms ? "itemDisabled" : "")
                }
              >
                <p>Nasal Congestion</p>
                {handleSymptomsStyle("Nasal Congestion") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoSymptoms && handleSymptoms("Dry cough");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleSymptomsStyle("Dry cough") ? "itemSelected" : "") +
                  (isNoSymptoms ? "itemDisabled" : "")
                }
              >
                <p>Dry cough</p>
                {handleSymptomsStyle("Dry cough") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={(evt) => {
                  !isNoSymptoms && handleSymptoms("Running Nose");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleSymptomsStyle("Running Nose") ? "itemSelected" : "") +
                  (isNoSymptoms ? "itemDisabled" : "")
                }
              >
                <p>Running Nose</p>
                {handleSymptomsStyle("Running Nose") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={(evt) => {
                  !isNoSymptoms && handleSymptoms("Diarrhoea");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleSymptomsStyle("Diarrhoea") ? "itemSelected" : "") +
                  (isNoSymptoms ? "itemDisabled" : "")
                }
              >
                <p>Diarrhoea</p>
                {handleSymptomsStyle("Diarrhoea") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={(evt) => {
                  !isNoSymptoms && handleSymptoms("Abdominal pain");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleSymptomsStyle("Abdominal pain")
                    ? "itemSelected"
                    : " ") +
                  (isNoSymptoms ? "itemDisabled" : "")
                }
              >
                <p>Abdominal pain</p>
                {handleSymptomsStyle("Abdominal pain") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
