const statuses = {
  "new" : "New",
  "sample_collected": "Sample Collected",
  "sample_delivered": "Sample Delivered",
  "kit_delivered" : "Kit Delivered",
  "pending": "Pending",
  "lab_received": "Lab Received",
  "result_updated": "Result Updated",
  "failed": "Failed",
  "negative": "Negative",
  "positive": "Positive"
}

export default statuses