import React from 'react';
import PropTypes from 'prop-types';


import currentStepIcon from '../../../assets/svg/current-step-icon.svg';
import doneStepIcon from '../../../assets/svg/step-done-icon.svg';
import pendingSubmissionIcon from '../../../assets/svg/pending-submission-icon.svg';


const MobileSteps = ({initialStep}) => {
    return (
        <div className="step-container">
              <div className="steps mobile">
                <div className={"step " + (initialStep === 0 ? "selected": "")}>
                  {initialStep === 0  || initialStep < 0 ? (
                    <img src={currentStepIcon} alt="" />
                  ) : (
                    <img src={doneStepIcon} alt="" />
                  )}
                  
                  <span>Personal Details</span>
                </div>

                <div className={"step " + (initialStep === 1 ? "selected": "")}>
                  {(initialStep !== 1 && initialStep < 1) && (
                    <img src={pendingSubmissionIcon} alt="" />
                  )}
                  {initialStep === 1 &&  (
                    <img src={currentStepIcon} alt="" />
                  )} 
                  {initialStep > 1 && (
                    <img src={doneStepIcon} alt="" />
                  )}
                  <span>More Details</span>
                </div>

                <div className={"step " + (initialStep === 2 ? "selected": "")}>
                  {(initialStep !== 2 && initialStep < 2) && (
                    <img src={pendingSubmissionIcon} alt="" />
                  )}
                  {initialStep === 2 &&  (
                    <img src={currentStepIcon} alt="" />
                  )} 
                  {initialStep > 2 && (
                    <img src={doneStepIcon} alt="" />
                  )}
                    <span>Log Symptoms</span>
                </div>

                <div className={"step " + (initialStep === 3 ? "selected": "")} style={{marginRight: '20px'}}>
                  {(initialStep !== 3 && initialStep < 3) && (
                    <img src={pendingSubmissionIcon} alt="" />
                  )}
                  {initialStep === 3 &&  (
                     <img src={currentStepIcon} alt="" />
                  )} 
                  {initialStep > 3 && (
                     <img src={doneStepIcon} alt="" />
                  )}
                  <span>Medical Condition</span>
                </div>
              </div>
            </div>
    )
}

MobileSteps.propTypes = {
    initialStep: PropTypes.number
}

export default MobileSteps;