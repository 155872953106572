import React, { Component } from 'react'


export default class MoreDetails extends Component {
  render() { 

    let {
      massGathering,
      contactHistory,
      contactWithConfirmedPatient,
      contactWithTraveller,
      travelHistory,
      moreDetailsError,
      handleChange
    } = this.props;

    return (
      <div className="form formMoreDetails">
        <div className="formGrid">
          <div className="gridItem">
            <div className="itemWrapper">
              <h3 className="itemTitle">Mass Gathering</h3> 
              <p className="itemSubTitle">Have you been to any mass gathering recently?</p>
            </div>  
          </div>  
          <div className="gridItem">
            <div className="formControl">
              <div className="formFull justify-between">
                <p>
                  <input 
                    type="radio" id="massGatheringYes" 
                    name="mass_gathering" value={true} 
                    onChange={handleChange} />
                  <label htmlFor="massGatheringYes">Yes</label>
                </p>

                <p>
                  <input type="radio" id="massGatheringNo" 
                    name="mass_gathering" value={false}
                    onChange={handleChange} />
                  <label htmlFor="massGatheringNo">No</label>
                </p>

                {
                  !massGathering && moreDetailsError && 
                  <p className="radio-button-error"><sup>*</sup>Please select one of the options</p>
                }
              </div>
            </div>  
          </div>  
        </div>

        <div className="formGrid">
          <div className="gridItem">
            <div className="itemWrapper">
              <h3 className="itemTitle">Contact History</h3> 
              <p className="itemSubTitle">Have you been to any organizations where a Covid case was confirmed or managed?</p>
            </div>  
          </div>  
          <div className="gridItem">
            <div className="formControl">
              <div className="formFull justify-between">
                <p>
                  <input 
                    type="radio" id="contactHistoryYes" 
                    name="contact_history" value={true}
                    onChange={handleChange}
                  />
                  <label htmlFor="contactHistoryYes">Yes</label>
                </p>

                <p>
                  <input 
                    type="radio" id="contactHistoryNo" 
                    name="contact_history" value={false}
                    onChange={handleChange} 
                    />
                  <label htmlFor="contactHistoryNo">No</label>
                </p>

                {
                  !contactHistory && moreDetailsError && 
                  <p className="radio-button-error"><sup>*</sup>Please select one of the options</p>
                }
              </div>
            </div>
            
          </div>  
        </div>

        <div className="formGrid">
          <div className="gridItem">
            <div className="itemWrapper">
              <h3 className="itemTitle">Contact with Comfirmed Patient</h3> 
              <p className="itemSubTitle">Have you had contact with a suspected or confirmed Covid case-patient?</p>
            </div>  
          </div>  
          <div className="gridItem">
            <div className="formControl">
              <div className="formFull justify-between">
                <p>
                  <input 
                    type="radio" id="contactWithConfirmedYes" 
                    name="contact_confirmed" value={true}
                    onChange={handleChange} />
                  <label htmlFor="contactWithConfirmedYes">Yes</label>
                </p>

                <p>
                  <input 
                    type="radio" id="contactWithConfirmedNo"
                    name="contact_confirmed" value={false}
                    onChange={handleChange} />
                  <label htmlFor="contactWithConfirmedNo">No</label>
                </p>

                {
                  !contactWithConfirmedPatient && moreDetailsError && 
                  <p className="radio-button-error"><sup>*</sup>Please select one of the options</p>
                }
              </div>
            </div>
            
          </div>  
        </div>


        <div className="formGrid">
          <div className="gridItem">
            <div className="itemWrapper">
              <h3 className="itemTitle">Contact with Travellers</h3> 
              <p className="itemSubTitle">Have you had contact with travellers who have recently been overseas in the last 14 days?</p>
            </div>  
          </div>  
          <div className="gridItem">
            <div className="formControl">
              <div className="formFull justify-between">
                <p>
                  <input 
                    type="radio" id="contactWithTravellerYes" 
                    name="contact_travellers" value={true}
                    onChange={handleChange} />
                  <label htmlFor="contactWithTravellerYes">Yes</label>
                </p>

                <p>
                  <input 
                    type="radio" id="contactWithTravellerNo" 
                    name="contact_travellers" value={false}
                    onChange={handleChange}
                    />
                  <label htmlFor="contactWithTravellerNo">No</label>
                </p>

                {
                  !contactWithTraveller && moreDetailsError && 
                  <p className="radio-button-error"><sup>*</sup>Please select one of the options</p>
                }
              </div>
            </div>

          </div>  
        </div>

        <div className="formGrid">
          <div className="gridItem">
            <div className="itemWrapper">
              <h3 className="itemTitle">Travel History</h3> 
              <p className="itemSubTitle">Have you been to any country outside your country of residence in the past month?</p>
            </div>  
          </div>  
          <div className="gridItem">
            <div className="formControl">
              <div className="formFull justify-between">
                <p>
                  <input 
                    type="radio" id="travelHistoryYes"
                    name="travel_history" value={true}
                    onChange={handleChange}
                    />
                  <label htmlFor="travelHistoryYes">Yes</label>
                </p>

                <p>
                  <input
                    type="radio" id="travelHistoryNo"
                    name="travel_history" value={false}
                    onChange={handleChange}
                    />
                  <label htmlFor="travelHistoryNo">No</label>
                </p>

                {
                  !travelHistory && moreDetailsError && 
                  <p className="radio-button-error"><sup>*</sup>Please select one of the options</p>
                }
              </div>
            </div>
            
          </div>  
        </div>
      </div>
    )
  }
}