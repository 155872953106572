import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

import AuthNav from "../../../nav/AuthNav";
import Button from "../../../_common/Button";
import APP_URL from "../../../../util/api";
import cancelForm from "../../../../assets/svg/cancel-form.svg";
import Loader from '../../../_common/loader/Loader';

const baseUrl = APP_URL;

class AddLab extends Component {
  state =  {
    name: "",
    email: "",
    firstName: "",
    lastName: "",
    phone_no: "",
    lab_area: "",
    lab_address: "",
    areas: [],
    isLoading: false,
  }

  componentDidMount() {
    this.fetchLGAS()
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback) => {
      return;
    };
}

  addLab = async (e) => {
    this.setState({ isLoading: true })
    const {
      name,
      email,
      phone_no,
      firstName,
      lastName,
      lab_area,
      lab_address,
    } = this.state;

    const payload = {
      name,
      email,
      phone: phone_no,
      lab_address,
      lab_area,
      firstName,
      lastName
    };

    let token = JSON.parse(localStorage.getItem('access_token'));

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    await axios
      .post(`${baseUrl}/admin/add/lab`, payload, config)
      .then(({ data }) => {
        this.notifySuccess('Lab created.')
        setTimeout(() => {
          window.location.reload()
        }, 1e3) 
      })
      .catch((error) => {
        error = error.response;
        let response = (error.data && error.data.error? error.data.error : 'An Error occurred.' )
        this.notifyError(response)
      });

    this.setState({ isLoading: false })
  };

  notifyError = (message) => toast.error(message);
  notifySuccess = (message) => toast.success(message);

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  fetchLGAS = () => {
    fetch(`${baseUrl}/areas`, {
      method: 'GET'
    }).then(response => response.json())
      .then(responseJSON => {
        this.setState({ areas: responseJSON})
      })
  }

  render() {
    const {
      areas,
      isLoading
    } = this.state;

    let areaOptions;
    if(areas.length > 0) {
      areaOptions = areas.map((areas, index) => 
        <option key={index}>{areas}</option>
      )
    }

    return (
      <main className="main-bg">
        <AuthNav page="labs" />

        <section className="lbDispatchSection">
          <div className="container smallContainer">
            <div className="formWrapper">
              <div className="formHeader flex">
                <h3>Add a Lab</h3>
              </div>

              <div className="form">
                <div className="formGrid">
                  <div className="gridItem" style={{ width: "60% !important" }}>
                    <div className="itemWrapper">
                      <div className="itemTitle">Laboratory Info</div>
                      <div className="itemSubTitle">
                        Add a little info about the Laboratory.
                      </div>
                    </div>
                  </div>
                  <div className="gridItem" style={{ width: "40% !important" }}>
                    <div className="formControl">
                      <div className={`formWrapper flexColumn`}>
                        <label htmlFor="phone">Lab Name</label>
                        <input
                          type="text"
                          onChange={this.handleChange}
                          name="name"
                        />
                      </div>

                      <div className={`formWrapper flexColumn`}>
                        <label htmlFor="phone">Lab's Email Address</label>
                        <input
                          type="email"
                          onChange={this.handleChange}
                          name="email"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="formGrid">
                  <div className="gridItem" style={{ width: "60% !important" }}>
                    <div className="itemWrapper">
                      <div className="itemTitle">Contact Person Info</div>
                      <div className="itemSubTitle">
                        Add a little info about the Lab's contact person.
                      </div>
                    </div>
                  </div>
                  <div className="gridItem" style={{ width: "40% !important" }}>
                    <div className="formControl">
                      <div className="formFull">
                        <div
                          className={`formWrapper flexColumn formHalf mr-20 mr-10`}
                        >
                          <label htmlFor="firstName">First Name</label>
                          <input
                            type="text"
                            onChange={this.handleChange}
                            name="firstName"
                          />
                        </div>

                        <div className={`formWrapper flexColumn formHalf`}>
                          <label htmlFor="lastName">Last Name</label>
                          <input
                            type="text"
                            onChange={this.handleChange}
                            name="lastName"
                          />
                        </div>
                      </div>

                      <div className={`formWrapper flexColumn`}>
                        <label htmlFor="phone_no">Phone Number</label>
                        <input
                          type="text"
                          onChange={this.handleChange}
                          name="phone_no"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="formGrid">
                  <div className="gridItem" style={{ width: "60% !important" }}>
                    <div className="itemWrapper">
                      <div className="itemTitle">Lab’s Location</div>
                      <div className="itemSubTitle">
                        Where is the lab located.
                      </div>
                    </div>
                  </div>
                  <div className="gridItem" style={{ width: "40% !important" }}>
                    <div className="formControl">
                      <div
                        className={`formWrapper flexColumn select p-0 mb-25`}
                        style={{ marginBottom: "20px !important" }}
                      >
                        <label
                          className="h-inherit"
                          style={{ padding: "3px 20px" }}
                        >
                          Lab's Area
                        </label>
                        <select
                          name="lab_area"
                          className="p-20 h-inherit"
                          onChange={this.handleChange}
                        >
                          <option></option>
                          {areaOptions}
                        </select>
                      </div>

                      <div className={`formWrapper flexColumn`}>
                        <label htmlFor="lab_address">Lab's Address</label>
                        <input
                          type="text"
                          onChange={this.handleChange}
                          name="lab_address"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justifyBetween align-center">
                <img src={cancelForm} alt="" onClick={() => this.props.history.goBack()}/>

                <Button
                  text="Add Lab"
                  textColor="#FFF"
                  onClick={this.addLab}
                  backgroundColor="#276DF7"
                />
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
        {isLoading && <Loader />}
      </main>
    );
  }
}

export default withRouter(AddLab);
