import React, { Component }  from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import REACT_APP_URL from '../../../../util/api'
import Loader from '../../../_common/loader/Loader'

const baseUrl = REACT_APP_URL


class AllDispatchLabsContainer extends Component {
  state = {
    labs: [],
    isLoading: false,
  }
  componentDidMount() {
    const { match : { params }} = this.props;

    let token = JSON.parse(localStorage.getItem('access_token'));

    axios.get(`${baseUrl}/admin/rider/${params.id}/views?token=${token}`)
      .then(({ data }) => {
        this.setState({labs: data.labs, isLoading: false})
      })
      .catch(error => {
        this.setState({isLoading: false})
      })
  } 

  render() {
    return (
      <div className="listingSection">
          <div className="table dispatchTable">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Lab Name</th>
                  <th>Contact Person</th>
                  <th>Phone Number</th>
                  <th>Location</th>
                </tr>
              </thead>
              
              <tbody>
                {this.state.labs && this.state.labs.map((item, index) => (
                  <tr className="tr" key={index}>
                    <td className="avatar_td">
                      <div className="avatar" style={{marginLeft: "10px"}}>
                        {item.name && <span>{item.name[0]}</span>}
                      </div>
                    </td>
                    <td>
                    {item.name}
                    </td>
                    <td>{item.users ? `${item.users.firstName} ${item.users.lastName}` : ''} </td>
                    <td>{item.users ? item.users.phone : ''}</td>
                    <td>{item.lab_address}</td>
                  </tr>
                ))}
              </tbody>
            </table>          
         </div>
         {this.state.isLoading && <Loader />}
      </div>
    )
  }
}


export default withRouter(AllDispatchLabsContainer)