import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import AuthNav from '../../../nav/AuthNav'
import LaboratoryListing from './LaboratoryListing'
import APP_URL from '../../../../util/api'
import Loader from '../../../_common/loader/Loader'
import { search } from '../../../../util/requestCreator'

const baseUrl = APP_URL;

class Labs extends Component {
  state = {
    labs: [],
    isLoading: false,
    loaderType: 'ball-pulse'
  }
  componentDidMount() {
    const token = JSON.parse(localStorage.getItem('access_token'));

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    this.setState({isLoading: true})

    axios.get(`${baseUrl}/admin/lab/all`, config)
      .then(({data}) => {
        this.setState({labs: data, isLoading: false})
      })
      .catch(error => {
        this.setState({isLoading: false})
      });
  }

  searchLabs = async (query) => {
    let token = JSON.parse(localStorage.getItem('access_token'));
    const data = await search(`${baseUrl}/admin/lab/q?query=${query}&token=${token}`)
    this.setState({ labs: data })
  }

  render() {
    const { isLoading } = this.state;
    return (
      <main className="main-bg">
        <AuthNav page="labs"/>
        {isLoading && <Loader/>}
        <LaboratoryListing data={this.state.labs} handleSearch={this.searchLabs} />
      </main>
    )
  }
}

export default withRouter(Labs)