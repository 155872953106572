import React, { Component } from 'react';
// import PropTypes from 'prop-types';

export default class PersonalDetails extends Component {
  render() {
    const {
      countryOptions,
      areaOptions,
      nationality,
      firstName,
      lastName,
      maritalStatus,
      dob,
      gender,
      email,
      phone,
      delivery_address,
      delivery_date,
      delivery_area,
      personalDetailsError,
      handleChange,
      deliveryDateFocused,
      onInputFocused,
      onInputBlurred
    } = this.props

    return (
      <div className="form formPersonalDetails">
        <div className="formGrid">
          <div className="gridItem">
            <div className="itemWrapper">
              <h3 className="itemTitle">Gender</h3> 
              <p className="itemSubTitle">How would you like to be identified.</p>
            </div>  
          </div>  
          <div className="gridItem">
            <div className="formControl">
              <div className="formFull justify-between">
                <p>
                  <input 
                    type="radio" 
                    id="male" name="gender" 
                    value="male"
                    checked={gender === "male"}
                    onChange={handleChange} />
                  <label htmlFor="male">Male</label>
                </p>

                <p>
                  <input 
                    type="radio" 
                    id="female" 
                    name="gender" 
                    value="female" 
                    checked={gender === "female"}
                    onChange={handleChange} />
                  <label htmlFor="female">Female</label>
                </p>
                {!gender && personalDetailsError && <p className="radio-button-error"><sup>*</sup>Please select one of the options</p>}
              </div>
            
            </div>
            
          </div>  
        </div>   
        
        <div className="formGrid">
          <div className="gridItem">
            <div className="itemWrapper">
              <div className="itemTitle">Tell us a little about yourself</div> 
              <div className="itemSubTitle">We keep your information private and safe.</div> 
            </div>  
          </div>  
          <div className="gridItem">
            <div className="formControl">
              <div className="formFull">
                <div className={`formWrapper flexColumn formHalf mr-20 mr-10 ${!firstName && personalDetailsError ? 'error' : ''}`}>
                  <label htmlFor="firstName">First Name</label>
                  <input 
                    type="text" 
                    name="first_name"
                    value={firstName}
                    onChange={handleChange} />
                </div> 

                <div className={`formWrapper flexColumn formHalf ${!lastName && personalDetailsError ? 'error' : ''}`}>
                  <label htmlFor="lastName">Last Name</label>
                  <input 
                    type="text"
                    name="last_name"
                    value={lastName}
                    onChange={handleChange} />
                </div> 
              </div>

              <div className={`formWrapper flexColumn ${!phone && personalDetailsError ? 'error' : ''}`}>
                <label htmlFor="phone">Phone Number</label>
                <input 
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={handleChange} />
              </div>


              <div className={`formWrapper flexColumn ${!email && personalDetailsError ? 'error' : ''}`}>
                <label htmlFor="email">Email Address</label>
                <input 
                  type="email"
                  name="email" 
                  value={email}
                  onChange={handleChange} />
              </div>
            </div>
          </div>   
        </div>     

        <div className="formGrid">
          <div className="gridItem">
            <div className="itemWrapper">
              <div className="itemTitle">Other details</div> 
              <div className="itemSubTitle">We keep your information private and safe.</div> 
            </div>
          </div>  
          <div className="gridItem">
            <div className="formControl">
              <div className="formFull flexRow">
                <div className={`formWrapper flexColumn ${!dob && personalDetailsError ? 'error' : ''}`}>
                  <label htmlFor="email">Date Of Birth</label>
                  <input
                    type="date"
                    name="dob"
                    value={dob}
                    onChange={handleChange}/>
                </div>
                {/* <div>
                  <input
                    className={`formDate ${!dob && personalDetailsError ? 'error' : ''}`}
                    type={dobFieldFocused ? "date" : "text"} 
                    placeholder="Date Of Birth"
                    name="dob"
                    onFocus={onInputFocused('dob')}
                    onBlur={onInputBlurred('dob')}
                    onChange={handleChange}/>
                </div>  */}

                <div className={`formWrapper flexColumn select p-0 m-0 ${!maritalStatus && personalDetailsError ? 'error' : ''}`}>
                  <select name="marital_status" className="p-20 h-inherit" 
                    value={maritalStatus}
                    onChange={handleChange}>
                    <option>Marital Status</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorced">Divorced</option>
                    <option value="separated">Separated</option>
                  </select>  
                </div>

                <div className={`formWrapper flexColumn select p-0 m-0 ${!nationality && personalDetailsError ? 'error' : ''}`}>
                  <select name="nationality" className="p-20 h-inherit" 
                    onChange={handleChange}>
                    <option>Nationality</option>
                    {countryOptions}
                  </select>
                </div>
              </div>
              
            </div>  
          </div> 
        </div>

        <div className="formGrid">
          <div className="gridItem">
            <div className="itemWrapper">
              <div className="itemTitle">Delivery details</div> 
              <div className="itemSubTitle">Where and when would you love your kit to be delivered.</div> 
            </div>  
          </div>  
          <div className="gridItem">
            <div className="formControl">
              <div className={`formInputWrapper ${!delivery_address && personalDetailsError ? 'error' : ''}`}>
                <input 
                  name="delivery_address"
                  type="text" placeholder="Delivery Address"
                  value={delivery_address}
                  onChange={handleChange} />
              </div>

              <div className={`formWrapper flexColumn select p-0 mb-25 ${!delivery_area && personalDetailsError ? 'error': ''}`}>
                  <select name="delivery_area" className="p-20 h-inherit" 
                    onChange={handleChange}>
                    <option>Delivery Area</option>
                    {areaOptions}
                  </select>  
                </div>

              <div className="formFull flexRow">
                <div>
                  <input
                    className={`formDate ${!delivery_date && personalDetailsError ? 'error' : ''}`}
                    name="delivery_date"
                    type={deliveryDateFocused ? "date" : "text"} placeholder="Delivery Date"
                    onFocus={onInputFocused('delivery_date')}
                    onBlur={onInputBlurred('delivery_date')}
                    value={delivery_date}
                    onChange={handleChange} />
                </div>
              </div>  
            </div>  
          </div> 
        </div>
      </div>
    )
  }
}

PersonalDetails.propTypes = {
  
}