import React from 'react';
import PropTypes from 'prop-types';

import currentStepIcon from '../../../assets/svg/current-step-icon.svg';
import doneStepIcon from '../../../assets/svg/step-done-icon.svg';
import pendingSubmissionIcon from '../../../assets/svg/pending-submission-icon.svg';

const DesktopSteps = ({initialStep}) => {
    return (
        
        <div className="container">
        <div className="steps desktop">
          <div className={"step " + (initialStep === 0 ? "selected": "")}>
            {initialStep === 0  || initialStep < 0 ? (
              <img alt="" src={currentStepIcon} />
            ) : (
              <img alt="" src={doneStepIcon} />
            )}
            
            <span>Personal Details</span>
          </div>

          <div className={"step " + (initialStep === 1 ? "selected": "")}>
            {(initialStep !== 1 && initialStep < 1) && (
              <img src={pendingSubmissionIcon} alt="" />
            )}
            {initialStep === 1 &&  (
              <img src={currentStepIcon} alt="" />
            )} 
            {initialStep > 1 && (
              <img src={doneStepIcon} alt="" />
            )}
            <span>More Details</span>
          </div>

          <div className={"step " + (initialStep === 2 ? "selected": "")}>
            {(initialStep !== 2 && initialStep < 2) && (
              <img src={pendingSubmissionIcon} alt="" />
            )}
            {initialStep === 2 &&  (
              <img src={currentStepIcon} alt="" />
            )} 
            {initialStep > 2 && (
              <img src={doneStepIcon} alt="" />
            )}
              <span>Log Symptoms</span>
          </div>

          <div className={"step " + (initialStep === 3 ? "selected": "")}>
            {(initialStep !== 3 && initialStep < 3) && (
                <img src={pendingSubmissionIcon} alt="" />
            )}
            {initialStep === 3 &&  (
              <img src={currentStepIcon} alt="" />
            )} 
            {initialStep > 3 && (
              <img src={doneStepIcon} alt="" />
            )}
            <span>Medical Condition</span>
          </div>
        </div>
      </div>
    )
}

DesktopSteps.propTypes = {
    initialStep: PropTypes.number,
}

export default DesktopSteps; 