import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'


class AllLabs extends Component {
  navigate = (path) => {
    this.props.history.push({
      pathname: path
    })
  }
  render() {
    const { data } = this.props;
    return (
      <div className="table dispatchTable">
        <table>
          <thead>
            <tr>
              <th  className="th-center"></th>
              <th>Name & Email Address</th>
              <th>Phone Number</th>
              <th>Available Delivery</th>
              <th>Location</th>
              <th></th>
            </tr>
          </thead>
          

          <tbody>
            {data && data.map((item, index) => (
              <tr className="tr" key={index}>
                <td className="avatar_td">
                  <div className="avatar">
                    {item.firstName && <span>{item.firstName[0]}</span>}
                    {item.lastName && <span>{item.lastName[0]}</span>}
                  </div>
                </td>
                <td>
                  <div className="flex flexColumn name-email">
                    <div>{item.firstName} {item.lastName}</div>
                    <div>{item.email}</div>
                  </div>
                
                </td>
                <td>{item.phone_no}</td>
                <td>{moment(item.deliveryDate).format('LL')}</td>
                <td>{item.deliveryAddress}</td>
                <td>
                  <button className='view-btn' onClick={() => this.navigate(`/tester/profile/${item.id}`)}>View</button>
                </td>
              </tr>
            ))}
            {
              data && data.length === 0 && 
              <tr>
                <td colSpan="5">
                  <h3 style={{padding:'30px', textAlign:'center'}}>No unassigned testers at the moment.</h3>
                </td>
              </tr>
            }
          </tbody>
        </table>          
      </div>
            
    )
  }
}

export default withRouter(AllLabs);