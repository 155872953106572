import React, { Component } from 'react';
import moment from 'moment';
import statuses from '../../../util/statuses'

export default class AssignedKits extends Component {
    render() {
        const { data, openModal, searchCode } = this.props;
        return (
            <React.Fragment>
                <table>
                    <thead>
                        <tr>
                            <th>Kit Code</th>
                            <th>Delivery Date</th>
                            <th>Dispatch Rider</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map((item) => {
                                const { id, kitCode, personnel, case: tester, status } = item;
                                const { deliveryDate } = tester;
                                const { firstName, lastName } = personnel;
                                const name = `${firstName} ${lastName}`;
                                return (
                                    <tr className="tr" key={id}>
                                        <td>{kitCode}</td>
                                        <td>{moment(deliveryDate).format('MMM DD, YYYY')}</td>
                                        <td>{name}</td>
                                        <td>
                                            <div className={'badges ' + (status ? status : 'default')}>
                                                <span>{statuses[status]}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <button 
                                                className='view-btn' 
                                                onClick={() => { openModal(kitCode, name, id) }}
                                                disabled={status!=='sample_delivered'}
                                                >
                                                Receive Kit
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        {
                            data && data.length === 0 && !searchCode &&
                            <tr className="tr">
                                <td colSpan="4" style={{ textAlign: 'center' }}>There are no assigned kits at the moment.</td>
                            </tr>
                        }
                        {
                            data && data.length === 0 && !!searchCode &&
                            <tr className="tr">
                                <td colSpan="4" style={{ textAlign: 'center' }}>There are no assigned kits with the code {searchCode}.</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}
