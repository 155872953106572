import React, { Component } from "react";
import moment from 'moment';

export default class ReceivedKits extends Component {
    render() {
        const { data, navigate, searchCode } = this.props;
        return (
            <React.Fragment>
                <table>
                    <thead>
                        <tr>
                            <th>Kit Code</th>
                            <th>Delivery Date</th>
                            <th>Dispatch Rider</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map((item) => {
                                const { id, kitCode, personnel, case: tester } = item;
                                const { deliveryDate } = tester;
                                const { firstName, lastName } = personnel;
                                const name = `${firstName} ${lastName}`;
                                return (
                                    <tr className="tr" key={id}>
                                        <td>{kitCode}</td>
                                        <td>{moment(deliveryDate).format('MMM DD, YYYY')}</td>
                                        <td>{name}</td>
                                        <td>
                                            <button
                                                className="view-btn"
                                                onClick={() =>
                                                    navigate(`/laboratory/kit/${id}/${kitCode}`)
                                                }
                                            >
                                                Upload Result
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        {
                            data && data.length === 0 && !searchCode &&
                            <tr className="tr">
                                <td colSpan="4" style={{ textAlign: 'center' }}>There are no received kits at the moment.</td>
                            </tr>
                        }
                        {
                            data && data.length === 0 && !!searchCode &&
                            <tr className="tr">
                                <td colSpan="4" style={{ textAlign: 'center' }}>There are no received kits with the code {searchCode}.</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}
