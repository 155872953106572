import React, { Component } from 'react'
import Modal from '../../../modal/Modal';
import QrReader from 'react-qr-reader'

export default class ScanKit extends Component {
  state = {
    currentScreen: 0,
    showRemoveKitModal: false,
    showAssignKitModal: false,
  }

  closeRemoveKitModal = (e) => {
    this.setState({showRemoveKitModal: false})
  }

  closeAssignKitModal = (e) => {
    this.setState({showAssignKitModal: false})
  }

  removeKit = () => {
    this.props.assignToTester('kitCode', null)
    this.setState({
      showRemoveKitModal: false,
      currentScreen: 0
    })
  }


  handleScan = (data) => {
    if (data) {
      this.props.assignToTester('kitCode', data)
      this.setState({
        code: data,
        currentScreen: 1
      })
    }
  }

  handleError = (err) => {
    console.error(err)
  }

  render() {
    let {
      currentScreen,
      code
    } = this.state;
    const previewStyle = {
        height: 'auto',
        width: '60%',
        borderRadius: '6px',
        margin: '0 auto'
    }
    return (
      <div className="scan-kit">
        <div className="container">
          <div className="scan-wrapper">
            <h5>Scan a kit to assign to {this.props.name}</h5>

            {currentScreen === 0 && (
              <React.Fragment>
                  <QrReader
                    delay={1000}
                    style={previewStyle}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    facingMode='environment'
                  />
              </React.Fragment>
            )}

            {currentScreen === 1 && (
              <React.Fragment>
                <div className="select-kit flex flexColumn">
                  <div className="select-wrapper">
                    <div className="flex justifyBetween align-center p-20">
                      <div className="kitCode">{code}</div>
                      <svg 
                        onClick={() => this.setState({showRemoveKitModal: true})}
                        className="cancel" 
                        width="16" height="16" 
                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.04208 7.99999L14.7842 2.25786C15.0719 1.9701 15.0719 1.50356 14.7842 1.21582C14.4964 0.928059 14.0299 0.928059 13.7421 1.21582L7.99999 6.95795L2.25786 1.21582C1.9701 0.928059 1.50356 0.928059 1.21582 1.21582C0.928086 1.50359 0.928059 1.97013 1.21582 2.25786L6.95795 7.99999L1.21582 13.7421C0.928059 14.0299 0.928059 14.4964 1.21582 14.7841C1.50359 15.0719 1.97013 15.0719 2.25786 14.7841L7.99999 9.04203L13.7421 14.7841C14.0298 15.0719 14.4964 15.0719 14.7841 14.7841C15.0719 14.4964 15.0719 14.0298 14.7841 13.7421L9.04208 7.99999Z" fill="#5E6C84" stroke="#5E6C84" strokeWidth="2"/>
                      </svg>
                    </div>
                  </div>
                </div>

                <button className="scan-kit-btn" onClick={() => this.setState({showAssignKitModal: true})}>Assign Kit</button>
              </React.Fragment>
            )}

          </div>

          <Modal show={this.state.showRemoveKitModal} handleClose={this.closeRemoveKitModal}>
              <div className="modal-container">
                <h4>Remove Kit {code}</h4>
                <p>Are you sure you want to remove kit with the code {code} assigned to {this.props.name} </p>

                <div className="flex justifyBetween">
                  <div></div>

                  <div className="buttons-wrapper flex">
                    <p className="go-back" onClick={this.closeRemoveKitModal}>Go Back</p>
                    <button className="modal-btn" onClick={this.removeKit}>Yes, Remove</button>
                  </div>
                </div>
              </div>
          </Modal>

          <Modal show={this.state.showAssignKitModal} handleClose={this.closeAssignKitModal}>
              <div className="modal-container">
                <h4>Assign a Kit {code}</h4>
                <p>Are you sure you are assigning the kit with the code {code} to {this.props.name} </p>

                <div className="flex justifyBetween">
                  <div></div>

                  <div className="buttons-wrapper flex">
                    <p className="go-back" onClick={this.closeAssignKitModal}>Go Back</p>
                    <button className="modal-btn" onClick={() => this.props.assignKit()}>Yes, Assign</button>
                  </div>
                </div>
              </div>
          </Modal>
        </div>
      </div>
    )
  }
}