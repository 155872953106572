import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'

import Reschedule from './Reschedule';

import Modal from '../../../modal/Modal';
import AuthNav from '../../../nav/AuthNav';
import Button from '../../../_common/Button';
import { GoBackIcon } from '../../../_common/Icons';
import APP_URL from '../../../../util/api';
import Loader from '../../../_common/loader/Loader';

const baseUrl = APP_URL;

const style = {
  LinkStyle: {
    textDecoration: "none",
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#18131A",
  },
  AvatarP: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '25px',
    marginLeft: '8px'
  }
}


class TesterProfile extends Component {
  state = {
    currentPage: 0,
    firstName: '',
    lastName: '',
    phone_no: '',
    email: '',
    deliveryAddress: '',
    deliveryArea: '',
    areas: [],
    datePickerIsOpen: false,
    isLoading: false
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    this.setState({
      id: params.id
    }, () => this.fetchCases())
    
    this.fetchLGAS()
  }

  fetchCases = async () => {
    this.setState({isLoading: true});
    let token = JSON.parse(localStorage.getItem('access_token'));
    const { id } = this.state;

    await axios.get(`${baseUrl}/admin/cases/${id}?token=${token}`)
      .then(({ data }) => {
        console.log(data);
        this.setState({ ...data.data })
      })
      .catch(error => {
        console.log(error.response);
      })
    this.setState({isLoading: false});
  }

  fetchLGAS = () => {
    fetch(`${baseUrl}/areas`, {
      method: 'GET'
    }).then(response => response.json())
      .then(responseJSON => {
        this.setState({ areas: responseJSON })
      })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  setPage = (t) => (_) => {
    this.setState({
      currentPage: t
    }, () => console.dir(t))
  }

  navigate = (route) => {
    this.props.history.push({
      pathname: route
    })
  }

  navigate = (route) => {
    this.props.history.push(route)
  }

  goBack = () => {
    this.props.history.goBack();
  }

  openModal = () => {
    this.setState({
      rescheduleModal: true,
    })
  }

  closeModal = () => {
    this.setState({
      rescheduleModal: false,
    })
  }

  onSuccessClose = () => {
    this.closeModal();
    this.fetchCases();
  }

  render() {
    const {
      id,
      areas,
      isAssigned,
      rescheduleModal,
      firstName,
      lastName,
      phone_no,
      email,
      deliveryDate,
      deliveryArea,
      deliveryAddress,
      dispatch,
      isLoading
    } = this.state;

    const status = dispatch && dispatch.status;

    let areaOptions;
    if (areas.length > 0) {
      areaOptions = areas.map((areas, index) =>
        <option key={index}>{areas}</option>
      )
    }
    return (
      <main className="main-bg">
        <AuthNav page="testers" />
        <div className="listings">
          <div className="top">
            <div className="flex" onClick={() => this.goBack()}>
              <GoBackIcon />
              <span style={{ color: '#276DF7', marginLeft: '5px', cursor: 'pointer' }}>Back</span>
            </div>
            <div className="title-search">
              <h4>Tester Profile</h4>
            </div>
          </div>
          <div className="content">
            <div>
              <div className="sideBarNav">
                <ul>
                  <li className={(this.state.currentPage === 0 ? "focus" : "")}>
                    <div
                      style={style.LinkStyle}
                      onClick={() => this.setPage(0)}
                    >Profile</div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              {this.state.currentPage === 0 && (
                <div className="dispatchProfile">
                  <div className="profileContainer">
                    <div className="profileHeader">
                      <div className="profileHeaderWrapper">
                        <div className="flex justifyBetween align-center">
                          <div className="flex align-center">
                            <div className="bigAvatarProfile flex justifyCenter align-center">
                              {this.state.firstName && <span>{this.state.firstName[0]}</span>}
                              {this.state.lastName && <span>{this.state.lastName[0]}</span>}
                            </div>
                            <p style={style.AvatarP}>
                              {this.state.firstName} {" "} {this.state.lastName}
                            </p>
                          </div>

                          <div className="flex justifyAround">
                            {
                              isAssigned === 0 &&
                              <Button
                                text='Assign a Kit'
                                width='123px'
                                height='40px'
                                backgroundColor='#276DF7'
                                textColor='#FFF'
                                onClick={() => this.navigate(`/testers/assign/${id}`)}
                              />
                            }
                            {
                              (!dispatch || status === 'pending' || status === 'failed') &&
                              <Button
                                text="Reschedule"
                                width='123px'
                                height='40px'
                                backgroundColor='rgba(254, 244, 244, 0.01)'
                                borderColor='#276DF7'
                                textColor='#276DF7'
                                onClick={this.openModal}
                              />
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="profileFormSection">
                      <div className="form">
                        <div className="formGrid">
                          <div className="gridItem" style={{ width: "60% !important" }}>
                            <div className="itemWrapper">
                              <div className="itemTitle">Testers Information</div>
                              <div className="itemSubTitle">Add a little info about the tester.</div>
                            </div>
                          </div>
                          <div className="gridItem" style={{ width: "40% !important" }}>
                            <div className="formControl">
                              <div className="formFull">
                                <div className={`formWrapper flexColumn formHalf mr-20 mr-10`}>
                                  <label htmlFor="firstName">First Name</label>
                                  <input
                                    type="text"
                                    value={firstName}
                                    onChange={this.handleChange}
                                    name="firstName"
                                    disabled />
                                </div>

                                <div className={`formWrapper flexColumn formHalf`}>
                                  <label htmlFor="lastName">Last Name</label>
                                  <input
                                    type="text"
                                    value={lastName}
                                    onChange={this.handleChange}
                                    name="lastName"
                                    disabled />
                                </div>
                              </div>

                              <div className={`formWrapper flexColumn`}>
                                <label htmlFor="phone">Phone Number</label>
                                <input
                                  type="text"
                                  value={phone_no}
                                  onChange={this.handleChange}
                                  name="phone"
                                  disabled />
                              </div>


                              <div className={`formWrapper flexColumn`}>
                                <label htmlFor="email">Email Address</label>
                                <input
                                  type="email"
                                  value={email}
                                  onChange={this.handleChange}
                                  name="email"
                                  disabled />
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="formGrid">
                          <div className="gridItem" style={{ width: "60% !important" }}>
                            <div className="itemWrapper">
                              <div className="itemTitle">Delivery Details</div>
                              <div className="itemSubTitle">Delivery date and address of the tester.</div>
                            </div>
                          </div>
                          <div className="gridItem" style={{ width: "40% !important" }}>
                            <div className="formControl">
                              <div className={`formWrapper flexColumn`}>
                                <label htmlFor="address">Delivery Date</label>
                                <input
                                  type="text"
                                  onChange={this.handleChange}
                                  value={moment(deliveryDate).format('LL')}
                                  name="deliveryDate"
                                  disabled />
                              </div>

                              <div className={`formWrapper flexColumn`}>
                                <label htmlFor="address">Delivery Area</label>
                                <select
                                  name='deliveryArea'
                                  onChange={this.handleChange}
                                  className="p-20 h-inherit"
                                  style={{ border: 'none', outline: 'none', background: 'inherit' }}
                                  value={deliveryArea || ''}
                                  disabled>
                                  {areaOptions}
                                </select>
                              </div>

                              <div className={`formWrapper flexColumn`}>
                                <label htmlFor="address">Delivery Address</label>
                                <input
                                  type="text"
                                  onChange={this.handleChange}
                                  value={deliveryAddress}
                                  name="deliveryAddress"
                                  disabled />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal show={rescheduleModal} handleClose={this.closeModal}>
          <Reschedule 
            id={id}
            deliveryDate={deliveryDate}
            firstName={firstName}
            lastName={lastName}
            onSuccessClose={this.onSuccessClose}
            closeModal={this.closeModal}
          />
        </Modal>
        {isLoading && <Loader />}
      </main>
    )
  }
}

export default withRouter(TesterProfile)