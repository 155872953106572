import React from 'react'
import modalClose from '../../assets/svg/modal-close.svg'

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="flex justifyBetween close-menu-wrapper">
          <div></div>
          <img alt="" src={modalClose} onClick={handleClose}/>
        </div>
        {children}
      </section>
    </div>
  );
};

export default Modal