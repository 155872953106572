import React, { Component } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import Button from '../../../_common/Button'
import REACT_APP_URL from '../../../../util/api'
import Loader from '../../../_common/loader/Loader'
import { search } from '../../../../util/requestCreator'

let baseUrl = REACT_APP_URL;

let adminRoles = {
  "application_admin": "Super Admin",
  "admin": "Sub Admin"
}


class AdminListing extends Component {
  state = {
    isLoading: false,
    admins: []
  }
  navigate = (route) => {
    this.props.history.push({
      pathname: route
    })
  }

  componentDidMount() {
    this.fetchAdmins();
  }

  fetchAdmins = () => {
    let token = JSON.parse(localStorage.getItem('access_token'));

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    this.setState({isLoading: true})
    axios.get(`${baseUrl}/admins`, config)
      .then(({ data }) => {
        this.setState({admins: data, isLoading: false})
      })
      .catch(error => {
        this.setState({isLoading: false})
      })
  }

  filterAdmin = (e) => {
    this.setState({
    }, () => this.searchAdmins(e.target.value))
  }

  searchAdmins = async (query) => { 
    this.setState({isLoading: true})
    let token = JSON.parse(localStorage.getItem('access_token'));
    const data = await search(`${baseUrl}/admin/list/search?query=${query}&token=${token}`)
    this.setState({ admins: data, isLoading: false})
  }

  render() {
    let { isLoading } = this.state;
    return (
      <section className="lbDispatchSection">
          <div className="container">
            <div className="listingSection">
              <div className="tableFilter">
                <div className="flex justifyBetween">
                  <h4>Admin List</h4>

                  <div className="filter flex align-center" >
                    <div className="searchInputWrapper flex align-center">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.31461 0C8.25163 0 10.6292 2.3749 10.6292 5.3086L10.6293 5.30862C10.6293 6.62152 10.154 7.8232 9.34269 8.75907L12 11.4134L11.4127 12L8.7554 9.3457C7.83206 10.1424 6.62905 10.6172 5.31461 10.6172C2.37759 10.6172 0 8.22805 0 5.3086C0 2.38915 2.37759 0 5.31461 0ZM0.839243 5.3086C0.839243 7.7676 2.85292 9.779 5.3147 9.779C7.79005 9.779 9.79017 7.7676 9.79017 5.3086C9.79017 2.8496 7.77649 0.8382 5.3147 0.8382C2.85292 0.8382 0.839243 2.8496 0.839243 5.3086Z" fill="#262626" fillOpacity="0.5"/>
                      </svg>
                      <input type='text' placeholder='Search for an admin' onChange={(e) => this.searchAdmins(e.target.value)} />
                    </div>
                    <Button
                      text='Add Admin'
                      textColor="#FFF"
                      backgroundColor="#276DF7"
                      isPrimary={false}
                      fontSize="14px"
                      height="40px"
                      width="158px"
                      className="m-0"
                      onClick={() => this.navigate('/admin/add')}
                    />
                  </div>
                </div>
              </div>
            
              {this.state.isLoading && <Loader />}
              <div className="table dispatchTable">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Role</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.admins && this.state.admins.map((item, index) => {
                        let role = undefined;
                        if(item.roles && item.roles.name) {
                          role = adminRoles[item.roles.name]
                        } else if(Array.isArray(item.roles) && item.roles.length > 0 && item.roles[0].name) {
                          role = adminRoles[item.roles[0].name]
                        }
                        
                        return (
                          <tr className="tr" key={index}>
                            <td className="avatar_td">
                              <div className="avatar" style={{marginLeft: "4px"}}>
                                {item.firstName && <span>{item.firstName[0]}</span>}
                                {item.lastName && <span>{item.lastName[0]}</span>}
                              </div>
                            </td>
                            <td>
                              <div className="flex flexColumn name-email">
                                <div>{item.firstName || ''} {item.lastName || ''}</div>
                                <div>{item.email || ''}</div>
                              </div>
                            </td>
                            <td>{item.phone || ''}</td>
                            <td>{role}</td>
                            <td>
                              <button className='view-btn' onClick={() =>  this.navigate(`/admin/profile/${item.id}`)}>View</button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                </table>
              </div>
            </div>
          </div>
          {isLoading && <Loader />}
        </section>
    )
  }
}

export default withRouter(AdminListing)