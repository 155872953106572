import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import AuthNav from '../../../nav/AuthNav';
import { withRouter } from 'react-router-dom';
import Button from '../../../_common/Button';
import cancelForm from '../../../../assets/svg/cancel-form.svg';
import REACT_APP_URL from '../../../../util/api';


const baseUrl = REACT_APP_URL;


class AddAdmin extends Component {
  state = {
    firstName: '',
    lastname: '',
    email: '',
    phone: '',
    role: ''
  }

  handleChange = (evt) => {
    this.setState({[evt.target.name]:evt.target.value})
  }

  notifyError = (msg) => toast.error(msg);
  notifySuccess = (msg) => toast.success(msg);

  addAdmin = (e) => {
    let token = JSON.parse(localStorage.getItem('access_token'));

    axios.post(`${baseUrl}/admin?token=${token}`, this.state)
      .then(({ data }) => {
        this.notifySuccess('Admin Created.')
        this.resetState()
      })
      .catch(error => {
        this.notifyError('Error creating admin.')
      })
  }

  resetState = () => {
    this.setState({ firstName: '', lastName: '', role: '', email: '', phone: ''})
  }
  render() {
    return (
      <main className="main-bg">
        <AuthNav page="admin" />

        <section className="lbDispatchSection">
          <div className="container smallContainer">
            <div className="formWrapper">
              <div className="formHeader flex">
                <h3>Add an Admin</h3>
              </div>

              <div className="form">
                <div className="formGrid">
                  <div className="gridItem" style={{width: "60% !important"}}>
                    <div className="itemWrapper">
                      <div className="itemTitle">Role</div> 
                      <div className="itemSubTitle">What role would you like this admin to have.</div> 
                    </div>  
                  </div>  
                  <div className="gridItem" style={{width: "40% !important"}}>
                    <div className="formControl">
                      <div className="formFull justify-between">
                          <p>
                            <input type="radio" id="super_admin" name="role" value="application_admin" onChange={this.handleChange} />
                            <label htmlFor="super_admin">Super Admin</label>
                          </p>

                          <p>
                            <input type="radio" id="sub_admin" name="role" value="admin" onChange={this.handleChange}/>
                            <label htmlFor="sub_admin">Sub Admin</label>
                          </p>
                      </div>
                    </div>
                  </div>   
                </div>

                <div className="formGrid">
                  <div className="gridItem" style={{width: "60% !important"}}>
                    <div className="itemWrapper">
                      <div className="itemTitle">Admin's Information</div> 
                      <div className="itemSubTitle">Add a little info about the admin</div> 
                    </div>  
                  </div>  
                  <div className="gridItem" style={{width: "40% !important"}}>
                    <div className="formControl">
                      <div className="formFull">
                        <div className={`formWrapper flexColumn formHalf mr-20 mr-10`}>
                          <label htmlFor="firstName">First Name</label>
                          <input 
                            type="text" 
                            name="firstName" 
                            value={this.state.firstName || ''}
                            onChange={this.handleChange}
                            />
                        </div> 

                        <div className={`formWrapper flexColumn formHalf`}>
                          <label htmlFor="lastName">Last Name</label>
                          <input 
                            type="text"
                            name="lastName" 
                            value={this.state.lastName || ''}
                            onChange={this.handleChange}
                            />
                        </div> 
                      </div>

                      <div className={`formWrapper flexColumn`}>
                        <label htmlFor="phone">Phone Number</label>
                        <input 
                          type="text"
                          name="phone"
                          value={this.state.phone || ''} 
                          onChange={this.handleChange} 
                          />
                      </div>

                      <div className={`formWrapper flexColumn`}>
                        <label htmlFor="phone">Email Address</label>
                        <input 
                          type="email"
                          name="email"
                          value={this.state.email || ''}
                          onChange={this.handleChange}
                          />
                      </div>
                      
                    </div>
                  </div>   
                
                </div>
              </div>   

              <div className="flex justifyBetween align-center">
                <img src={cancelForm}  alt="" />

                <Button 
                  text="Add Admin"
                  textColor="#FFF"
                  backgroundColor="#276DF7"
                  onClick={this.addAdmin}
                  />

              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </main>
    )
  }
}

export default withRouter(AddAdmin)