import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import Button from '../../../_common/Button'
import AuthNav from '../../../nav/AuthNav'
import cancelForm from '../../../../assets/svg/cancel-form.svg'
import APP_URL from '../../../../util/api'
import Loader from 'react-loaders';

const baseURl = APP_URL;

let initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  area: '',
  address: '',
  refId: '',
  areas: [],
  isLoading: false,
}

class CreateDispatchRider extends Component {
  state = initialState;


  fetchLGAs = () => {
    axios.get(`${baseURl}/areas`).then(({data}) => this.setState({areas: data}))
  }

  componentDidMount() {
    this.fetchLGAs()
  }
  createDispatch = () => {
    this.setState({isLoading: true})
    let token = JSON.parse(localStorage.getItem('access_token'));

    let {
      firstName,
      lastName,
      email,
      phone,
      area,
      address,
      refId,
    } = this.state;
 

    let payload = {
      firstName,
      lastName,
      email,
      phone,
      area,
      address,
      refId
    }

    axios.post(`${baseURl}/admin/add/rider?token=${token}`, payload)
    .then(result => {
      this.notifySuccess('Rider created.')
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return
    })
    .catch(error => { 
      this.notifyError('An Error occurred.')
      this.setState({isLoading: false})
    })
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  notifyError = (message) => toast.error(message);
  notifySuccess = (message) => toast.success(message);

  render() {
    let areaOptions;
    if(this.state.areas.length > 0) {
      areaOptions = this.state.areas.map((areas, index) => 
        <option key={index}>{areas}</option>
      )
    }

    return (
      <main className="main-bg">
        <AuthNav page='dashbaord'/>

        <section className="lbDispatchSection">
          <div className="container smallContainer">
            <div className="formWrapper">
              <div className="formHeader flex">
                <h3>Add a Dispatch Rider</h3>
              </div>

              <div className="form">
                <div className="formGrid">
                  <div className="gridItem" style={{width: "60% !important"}}>
                    <div className="itemWrapper">
                      <div className="itemTitle">Dispatch Rider Info</div> 
                      <div className="itemSubTitle">Add a little info about the Dispatch rider.</div> 
                    </div>  
                  </div>  
                  <div className="gridItem" style={{width: "40% !important"}}>
                    <div className="formControl">
                      <div className="formFull">
                        <div className={`formWrapper flexColumn formHalf mr-20 mr-10`}>
                          <label htmlFor="firstName">First Name</label>
                          <input 
                            onChange={this.handleChange}
                            type="text" 
                            name="firstName"/>
                        </div> 

                        <div className={`formWrapper flexColumn formHalf`}>
                          <label htmlFor="lastName">Last Name</label>
                          <input
                            onChange={this.handleChange}
                            type="text"
                            name="lastName" />
                        </div> 
                      </div>

                      <div className={`formWrapper flexColumn`}>
                        <label htmlFor="phone">Phone Number</label>
                        <input
                          onChange={this.handleChange}
                          type="text"
                          name="phone"/>
                      </div>

                      <div className={`formWrapper flexColumn`}>
                        <label htmlFor="phone">Email Address</label>
                        <input
                          onChange={this.handleChange}
                          type="email"
                          name="email"/>
                      </div>
                      
                    </div>
                  </div>   
                
                </div>

                <div className="formGrid">
                  <div className="gridItem" style={{width: "60% !important"}}>
                    <div className="itemWrapper">
                      <div className="itemTitle">Dispatch Rider Ref ID</div> 
                      <div className="itemSubTitle">Enter the reference ID of the rider</div> 
                    </div>  
                  </div>  
                  <div className="gridItem" style={{width: "40% !important"}}>
                    <div className="formControl">
                      <div className="formFull">
                        <div className={`formWrapper flexColumn formFull`}>
                          <label htmlFor="refId">Ref ID</label>
                          <input 
                            onChange={this.handleChange}
                            type="text" 
                            name="refId"/>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>

                <div className="formGrid">
                  <div className="gridItem" style={{width: "60% !important"}}>
                    <div className="itemWrapper">
                      <div className="itemTitle">Riders’s Location</div> 
                      <div className="itemSubTitle">Where does the dispatch rider stay.</div> 
                    </div>  
                  </div>  
                  <div className="gridItem" style={{width: "40% !important"}}>
                    <div className="formControl">
                      <div className={`formWrapper flexColumn select p-0 mb-25`} style={{marginBottom: "20px !important"}}>
                        <label className="h-inherit" style={{padding: "3px 20px"}}>Rider's Area</label>
                        <select
                          onChange={this.handleChange} 
                          name="area" className="p-20 h-inherit">
                          <option></option>
                          {areaOptions}
                        </select>  
                      </div>


                      <div className={`formWrapper flexColumn`}>
                        <label htmlFor="address">Rider's Address</label>
                        <input 
                          onChange={this.handleChange}
                          type="text"
                          name="address"  />
                      </div>
                    </div>
                  </div>   
                </div> 
              </div>   

              <div className="flex justifyBetween align-center">
                <img src={cancelForm} alt="" onClick={() => this.props.history.goBack()}/>

                <Button 
                  onClick={this.createDispatch}
                  text="Add Dispatch Rider"
                  textColor="#FFF"
                  backgroundColor="#276DF7" />

              </div>
            </div>
          </div>
        </section>
        {this.state.isLoading && <Loader />}
        <ToastContainer />
      </main>
    )
  }
}

export default withRouter(CreateDispatchRider)