import React, { Component } from "react";
import check from "../../assets/svg/checkbox-check.svg";
import unchecked from "../../assets/svg/checkbox-unchecked.svg";

export default class MedicalCondition extends Component {
  render() {
    let { isNoCondition, handleConditionsStyle, handleConditions } = this.props;
    return (
      <div className="form formMedicalCondition">
        <div className="formGrid">
          <div className="gridItem">
            <div className="itemWrapper">
              <div className="itemTitle">Select pre-existing condition</div>
              <div className="itemSubTitle">
                What pre-existing condition do you have?
              </div>
            </div>
          </div>
          <div className="gridItem">
            <div className="formControl">
              <div
                onClick={() => handleConditions("No pre-existing condition")}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleConditionsStyle("No pre-existing condition")
                    ? "itemSelected"
                    : "")
                }
              >
                <p>No pre-existing condition</p>
                {handleConditionsStyle("No pre-existing condition") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoCondition && handleConditions("Pregnancy");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleConditionsStyle("Pregnancy") ? "itemSelected" : "") +
                  (isNoCondition ? "itemDisabled" : "")
                }
              >
                <p>Pregnancy</p>
                {handleConditionsStyle("Pregnancy") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoCondition && handleConditions("Obesity");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleConditionsStyle("Obesity") ? "itemSelected" : "") +
                  (isNoCondition ? "itemDisabled" : "")
                }
              >
                <p>Obesity</p>
                {handleConditionsStyle("Obesity") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoCondition && handleConditions("Heart disease");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleConditionsStyle("Heart disease")
                    ? "itemSelected"
                    : "") +
                  (isNoCondition ? "itemDisabled" : "")
                }
              >
                <p>Heart disease</p>
                {handleConditionsStyle("Heart disease") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoCondition &&
                    handleConditions("Asthma requiring medication");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleConditionsStyle("Asthma requiring medication")
                    ? "itemSelected"
                    : "") +
                  (isNoCondition ? "itemDisabled" : "")
                }
              >
                <p>Asthma requiring medication</p>
                {handleConditionsStyle("Asthma requiring medication") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoCondition &&
                    handleConditions("Chronic lung diesease (No asthma)");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleConditionsStyle("Chronic lung diesease (No asthma)")
                    ? "itemSelected"
                    : "") +
                  (isNoCondition ? "itemDisabled" : "")
                }
              >
                <p>Chronic lung diesease (No asthma)</p>
                {handleConditionsStyle("Chronic lung diesease (No asthma)") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoCondition && handleConditions("Chronic liver disease");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleConditionsStyle("Chronic liver disease")
                    ? "itemSelected"
                    : "") +
                  (isNoCondition ? "itemDisabled" : "")
                }
              >
                <p>Chronic liver disease</p>
                {handleConditionsStyle("Chronic liver disease") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoCondition &&
                    handleConditions("Chronic hematological disorder");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleConditionsStyle("Chronic hematological disorder")
                    ? "itemSelected"
                    : "") +
                  (isNoCondition ? "itemDisabled" : "")
                }
              >
                <p>Chronic hematological disorder</p>
                {handleConditionsStyle("Chronic hematological disorder") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoCondition && handleConditions("Chronic kidney disease");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleConditionsStyle("Chronic kidney disease")
                    ? "itemSelected"
                    : "") +
                  (isNoCondition ? "itemDisabled" : "")
                }
              >
                <p>Chronic kidney disease</p>
                {handleConditionsStyle("Chronic kidney disease") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoCondition &&
                    handleConditions("Chronic neurological disease");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleConditionsStyle("Chronic neurological disease")
                    ? "itemSelected"
                    : "") +
                  (isNoCondition ? "itemDisabled" : "")
                }
              >
                <p>Chronic neurological disease</p>
                {handleConditionsStyle("Chronic neurological disease") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>

              <div
                onClick={() => {
                  !isNoCondition &&
                    handleConditions("Organ or bone marrow recipient");
                }}
                className={
                  "formWrapper flex justifyBetween alignCenter " +
                  (handleConditionsStyle("Organ or bone marrow recipient")
                    ? "itemSelected"
                    : "") +
                  (isNoCondition ? "itemDisabled" : "")
                }
              >
                <p>Organ or bone marrow recipient</p>
                {handleConditionsStyle("Organ or bone marrow recipient") ? (
                  <img alt="" src={check} />
                ) : (
                  <img alt="" src={unchecked} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
