import React, { Component } from 'react';
import axios from 'axios'
import AuthNav from '../../nav/AuthNav';
import BarChart from './BarChart';
import REACT_APP_URL from '../../../util/api';
import roles from '../../../util/roles'
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Modal from '../../modal/Modal';
import { search } from '../../../util/requestCreator';
import Loader from '../../_common/loader/Loader';


const baseUrl = REACT_APP_URL;

class Overview extends Component {
    state = { adminOverview : {}, labOverview: {}, labs: [], showUnblockModal: false, selectedLab: '', selectedLabId: '', isLoading: false}

    componentDidMount() {
        const appData = JSON.parse(localStorage.getItem('app_data'));
        const role = appData && appData.role;

        if (role === 'application_admin' || role === 'admin') this.getAdminOverview();
        else this.getLabOverview();
    }

    getLabOverview = async () => {
        this.setState({isLoading: true})
        let token = JSON.parse(localStorage.getItem('access_token'));
        let config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        await axios.get(`${baseUrl}/lab/overview`, config)
        .then(data => {
            this.setState({
                labOverview: data.data.data,
                isLoading: false
            })
        })
        .catch(e => {
            console.log(e);
        })
        
        this.setState({
            isLoading: false
        })
    }

    getAdminOverview = async () => {
        this.setState({
            isLoading: true
        })

        let token = JSON.parse(localStorage.getItem('access_token'));
        let config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        let adminOverviewPromise = axios.get(`${baseUrl}/admin/overview`, config)
        let blockedProfilePromise = axios.get(`${baseUrl}/admin/view/blocked`, config)

        await Promise.all([adminOverviewPromise, blockedProfilePromise])
            .then( data => {
                console.log(data);
                this.setState({adminOverview: data[0].data, ...data[1].data})
            })

        this.setState({
            isLoading: false
        })
    }

    notifyError = (msg) => toast.error(msg);
    notifySuccess = (msg) => toast.success(msg);

    closeUnblockModal = (e) => this.setState({showUnblockModal: false})

    enableLab = (id) => {
        let token = JSON.parse(localStorage.getItem('access_token'));
        axios.put(`${baseUrl}/admin/enable/lab/${id}?token=${token}`)
          .then(({ data }) => {
            this.notifySuccess("Lab Enabled.")
            this.setState({showUnblockModal: false})
          })
          .catch(error => {
            this.notifyError('Error enabling lab.')
            this.setState({showUnblockModal: false})
          })
      }

    unblockProfile = (profile, profileId) => {
        let url = undefined;

        switch(profile) {
            case 'admin':
            case 'application_admin': 
            case 'lab_personnel':
            case 'rider':
                url = `${baseUrl}/admin/enable/user/${profileId}`
                break 
            case 'labs':
                url = `${baseUrl}/admin/enable/lab/${profileId}`
                break
            default:
                break
            
        }
        this.unblock(url)
    }

    unblock = (url) => {
        let token = JSON.parse(localStorage.getItem('access_token'));
        axios.put(`${url}?token=${token}`)
            .then(({ data }) => {
                if(data.success) {
                    this.notifySuccess('User unblocked.');
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                    
                }
            })
    }

    viewProfile = (profile, profileId) => {
        switch(profile) {
            case 'labs':
                this.props.history.push(`labs/profile/${profileId}/blocked`)
                break;
            case 'admin':
            case 'application_admin': 
                this.props.history.push(`admin/profile/${profileId}/blocked`)
                break
            case 'rider':
                this.props.history.push(`dispatch/profile/${profileId}/blocked`)
                break;
            default:
                break;
        }
    }

    searchProfiles = async (query) => {
        let token = JSON.parse(localStorage.getItem('access_token'));
        let config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const data = await search(`${baseUrl}/admin/view/blocked?filter=${query}`, config)
        this.setState({...data})
    }

    render() {
        const appData = JSON.parse(localStorage.getItem('app_data'));
        const role = appData && appData.role;
        const {
            adminOverview: { 
                Admins, 
                Failed_Deliveries,
                Negative, 
                Positive, 
                Tested,
                Riders,
                Labs,
                Requests,
            },
            labOverview: {
                assignedKits,
                samplesTested,
                negative,
                positive,
            },
            isLoading,
        } = this.state;

        const positiveCount = positive && positive.count;
        const negativeCount = negative && negative.count;
        return (
            <main className="main-bg">
                <AuthNav
                    page='overview'
                    name="SYNLAB Nigeria"
                />
                <div className="overview-page">
                    <div className="overview-top">
                        <div className="item">
                            <h3>{(role === 'application_admin' || role === 'admin') ? Requests : assignedKits}</h3>
                            <p>Assigned Kit</p>
                        </div>
                        <div className="item">
                            <h3>{Tested || samplesTested || 0}</h3>
                            <p>Samples Tested</p>
                        </div>
                        <div className="item">
                            <h3 className="negative">{Negative || negativeCount || 0}</h3>
                            <p>Negative Result</p>
                        </div>
                        <div className="item">
                            <h3 className="positive">{Positive || positiveCount || 0}</h3>
                            <p>Positive Result</p>
                        </div>
                        {
                            (role === 'application_admin' || role === 'admin') &&
                            <React.Fragment>
                                <div className="item">
                                    <h3>{Riders}</h3>
                                    <p>Dispatch Riders</p>
                                </div>
                                <div className="item">
                                    <h3>{Failed_Deliveries}</h3>
                                    <p>Failed Deliveries</p>
                                </div>
                                <div className="item">
                                    <h3>{Labs}</h3>
                                    <p>Labs</p>
                                </div>
                                <div className="item">
                                    <h3>{Admins}</h3>
                                    <p>Admin</p>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    {
                        role === 'lab_personnel' &&
                        <div className="chart-div">
                            <div className="title-key">
                                <p className="title">Number of Negative and Positive Cases Per Month</p>
                                <p className="negative"><span></span>Negative</p>
                                <p className="positive"><span />Positive</p>
                            </div>
                            <div className="chart">
                                <p>Sample Tested</p>
                                <div>
                                <BarChart negativeData={negative} positiveData={positive} />
                                </div>
                                <p className="month">Month</p>
                            </div>
                        </div>
                    }
                    {
                        (role === 'application_admin' || role === 'admin') &&
                        <div className="admin-overview-list">
                            <div className="top">
                                <div className="spacer"></div>
                                <div className="title-search">
                                    <h4>Blocked Profiles</h4>
                                    <div className="searchInputWrapper flex align-center">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.31461 0C8.25163 0 10.6292 2.3749 10.6292 5.3086L10.6293 5.30862C10.6293 6.62152 10.154 7.8232 9.34269 8.75907L12 11.4134L11.4127 12L8.7554 9.3457C7.83206 10.1424 6.62905 10.6172 5.31461 10.6172C2.37759 10.6172 0 8.22805 0 5.3086C0 2.38915 2.37759 0 5.31461 0ZM0.839243 5.3086C0.839243 7.7676 2.85292 9.779 5.3147 9.779C7.79005 9.779 9.79017 7.7676 9.79017 5.3086C9.79017 2.8496 7.77649 0.8382 5.3147 0.8382C2.85292 0.8382 0.839243 2.8496 0.839243 5.3086Z" fill="#262626" fillOpacity="0.5" />
                                        </svg>
                                        <input type='text' placeholder='Search' onChange={(e) => this.searchProfiles(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="table dispatchTable"><table>
                                <thead>
                                    <tr>
                                        <th className="th-center"></th>
                                        <th>Name</th>
                                        <th>Profile</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>


                                <tbody>
                                {this.state.users && this.state.users.map((item, index) => {
                                        return (
                                            <tr className="tr" key={index}> 
                                                <td className="avatar_td">
                                                    <div className="avatar" style={{ marginLeft: "10px" }}>
                                                        <span>{item.firstName[0]}</span>
                                                        <span>{item.lastName[0]}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex flexColumn name-email">
                                                        <div>{item.firstName} {item.lastName}</div>
                                                        <div>{item.email}</div>
                                                    </div>

                                                </td>
                                                <td>{item.roles && item.roles.length > 0 && <span>{roles[item.roles[0].name]}</span>}</td>
                                                <td>
                                                    <button className='view-btn unblock' onClick={() => this.unblockProfile(item.roles[0].name, item.id)} >Unblock</button>
                                                </td>
                                                <td>
                                                    <button className='view-btn' onClick={() => this.viewProfile(item.roles[0].name, item.id)} >View</button>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                    {this.state.labs && this.state.labs.map((item, index) => {
                                        return (
                                            <tr className="tr" key={index}>
                                                <td className="avatar_td">
                                                    <div className="avatar" style={{ marginLeft: "10px" }}>
                                                        <span>{item.name[0]}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex flexColumn name-email">
                                                        <div>{item.name}</div>
                                                        <div>{item.email}</div>
                                                    </div>

                                                </td>
                                                <td>Labs</td>
                                                <td>
                                                    <button className='view-btn unblock' onClick={() => this.setState({showUnblockModal: true, selectedLab: item.name, selectedLabId: item.id})} >Unblock</button>
                                                </td>
                                                <td>
                                                    <button className='view-btn' onClick={() => this.viewProfile('labs', item.id)} >View</button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    }
                    {isLoading && <Loader />}
                </div>
                <Modal show={this.state.showUnblockModal} handleClose={this.closeUnblockModal}>
                    <div className="modal-container">
                        <h4>Unblock {this.state.selectedLab}</h4>
                        <p>Are you sure you want to Unblock {this.state.selectedLab}? This means they will have access to the platform.</p>

                        <div className="flex justifyBetween">
                        <div></div>

                        <div className="buttons-wrapper flex">
                            <p className="go-back" onClick={this.closeUnblockModal}>Cancel</p>
                            <button className="modal-btn confirm-green" onClick={() => this.enableLab(this.state.selectedLabId)}>Unblock</button>
                        </div>
                        </div>
                    </div>
                </Modal>
                <ToastContainer />
            </main>
        )
    }
}

export default withRouter(Overview)
