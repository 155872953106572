import React from 'react'


const InputRow = ({label, value, children}) => (
  <div className="input-row">
    <label>{label}</label>
    <div>
      {children}
    </div>
  </div>
)

export default InputRow