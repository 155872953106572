import React from 'react'

const Button = ({isPrimary, text, backgroundColor, borderColor, textColor, width, height, padding, fontSize, onClick, disabled}) => {
  
  return (
    <button 
    onClick={onClick}
    className={"btn " + (isPrimary ? "primary-btn text-white flex align-center" : "")} 
    style={
      {background: backgroundColor,
      width: (width ? width : '248px'),
      height: (height ? height : '60px'),
      color: textColor, 
      fontSize: fontSize,
      outline: 'none',
      padding: (padding ? `${padding}` : ''),
      border: `1px solid ${borderColor}`}}
    disabled={disabled}>
    {text}
  </button>
  )
}
export default Button