import React, { Component } from 'react'
import Nav from '../../nav/Nav'
import { withRouter } from 'react-router-dom'
import ForgetPasswordForm from './ForgetPasswordForm'


class ForgotPassword extends Component {
  render() {
    return (
      <main className="main-bg p-0">
        <Nav />
        <div className="lbAdminFormWrapper">
          <ForgetPasswordForm />
        </div>
      </main>
    )
  }
}

export default withRouter(ForgotPassword)