import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import logo from '../../assets/svg/lifebank-logo.svg'
import NavProfile from './NavProfile'
import REACT_APP_URL from '../../util/api';

import { TestersIcon, DispatchIcon, LabsIcon, AdminIcon, OverviewIcon, KitsIcon } from '../_common/Icons';


const baseUrl = REACT_APP_URL;

const style = {
  LinkStyle: {
    textDecoration: "none",
    color: "#827F98",
  }
}

class AuthNav extends Component {
  state = {
    authUser: {},
    showProfileMenu: false,
  }

  componentDidMount() {
    const appData = JSON.parse(localStorage.getItem('app_data'));
    const role = appData && appData.role;
    const firstName = appData && appData.firstName;
    if (firstName) {
      const { firstName, lastName, avatar } = appData;
      this.setState({
        firstName, lastName, avatar
      })
    } else {
      this.fetchProfile();
    }
    this.setState({ role });
  }

  fetchProfile = () => {
    let token = JSON.parse(localStorage.getItem('access_token'));
    let config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    axios.get(`${baseUrl}/profile`, config)
      .then(({ data }) => {
        // console.log(data)
        const appData = JSON.parse(localStorage.getItem('app_data'));
        const newAppData = {...appData, ...this.formatData(data)}
        localStorage.setItem('app_data', JSON.stringify(newAppData));
        this.setState({...this.formatData(data)})
      })
  }

  formatData = (data) => {
    let result = {}
    if (data.role === 'lab_personnel'){
      const { firstName, lastName, avatar } = data;
      result = {
        firstName, lastName, avatar
      }
    } else {
      const { firstName, lastName, avatar } = data;
      result = {
        firstName, lastName, avatar
      }
    }
    return result;
  }

  toggleMenu = (e) => {
    this.setState({showProfileMenu: !this.state.showProfileMenu})
  }

  gotoProfile = () => {
    this.props.history.push('/profile/me')
  }

  logout = () => {
    localStorage.clear();
    window.location.reload()
  }

  render() {
    const { page } = this.props;
    const { role, firstName, lastName, avatar } = this.state;
    const name = firstName? `${firstName} ${lastName}` : '';
    return (
      <nav className="auth-nav">
        <div className="nav-container">
          <div className="flex flex-column justifyBetween mt-18">
            <div>
              <img src={logo} alt="" />
            </div>

            <ul className="nav-list flex">
              {
                role !== 'lab_personnel' &&
                <React.Fragment>
                  <li className={page === 'testers' ? 'active' : ''}>
                    <Link style={style.LinkStyle} to={'/testers'} className="flex align-center">
                      <TestersIcon color={page === 'testers' ? '#276DF7' : '#C9C9D6'} />
                      <p className="p-0 m-0">Tester</p>
                    </Link>
                  </li>

                  <li className={page === 'dispatch' ? 'active' : ''}>
                    <Link style={style.LinkStyle} to={'/dispatch'} className="flex align-center">
                      <DispatchIcon color={page === 'dispatch' ? '#276DF7' : '#C9C9D6'} />
                      <p className="p-0 m-0">Dispatch</p>
                    </Link>
                  </li>

                  <li className={page === 'labs' ? 'active' : ''}>
                    <Link style={style.LinkStyle} to={'/labs'} className="flex align-center">
                      <LabsIcon color={page === 'labs' ? '#276DF7' : '#C9C9D6'} />
                      <p className="p-0 m-0">Labs</p>
                    </Link>
                  </li>
                </React.Fragment>
              }
              {
                role === 'application_admin' &&
                <li className={page === 'admin' ? 'active' : ''}>
                  <Link style={style.LinkStyle} to={'/admin'} className="flex align-center">
                    <AdminIcon color={page === 'admin' ? '#276DF7' : '#C9C9D6'} />
                    <p className="p-0 m-0">Admin</p>
                  </Link>
                </li>
              }
              {
                role === 'lab_personnel' &&
                <li className={page === 'kits' ? 'active' : ''}>
                  <Link style={style.LinkStyle} to={'/laboratory'} className="flex align-center">
                    <KitsIcon color={page === 'kits' ? '#276DF7' : '#C9C9D6'} />
                    <p className="p-0 m-0">Test Kit</p>
                  </Link>
                </li>
              }

              <li className={page === 'overview' ? 'active' : ''}>
                <Link style={style.LinkStyle} to={'/overview'} className="flex align-center">
                  <OverviewIcon color={page === 'overview' ? '#276DF7' : '#C9C9D6'} />
                  <p className="p-0 m-0">Overview</p>
                </Link>
              </li>
            </ul>

            <NavProfile
              toggleProfileMenu={this.toggleMenu}
              imageUrl={avatar}
              name={name}
              logOut={this.logout}
              gotoProfile={this.gotoProfile}
              showProfileMenu={this.state.showProfileMenu}
            />

          </div>
        </div>
      </nav>
    )
  }
}


export default withRouter(AuthNav)