import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import AuthNav from '../../../nav/AuthNav';
import AssignedKits from '../../../_common/table/AssignedKits';
import ReceivedKits from '../../../_common/table/ReceivedKits';
import CompletedTests from '../../../_common/table/CompletedTests';
import Modal from '../../../modal/Modal';
import APP_URL from '../../../../util/api';
import Loader from '../../../_common/loader/Loader';

const baseUrl = APP_URL;

const style = {
    LinkStyle: {
        textDecoration: "none",
        fontFamily: "Muli",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "20px",
        color: "#827F98",
    },
}

const CancelToken = axios.CancelToken;
let cancel;

class Kits extends Component {
    state = {
        currentPage: 'assigned',
        data: null,
        receiveModal: false,
        kitCode: '',
        dispatchRider: '',
        id: null,
        searchCode: '',
        isLoading: false,
    }

    componentDidMount() {
        const { match: { params: { value } } } = this.props;
        this.setState({ currentPage: value }, () => this.loadKits());
    }

    handleSearchInput = (e) => {
        this.setState({
            searchCode: e.target.value,
        }, () => this.searchKits())
    }

    loadKits = async () => {
        this.setState({isLoading: true});
        let token = JSON.parse(localStorage.getItem('access_token'));

        const endpoint = this.setEndpoint();

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        await axios.get(`${baseUrl}/lab/${endpoint}`, config)
            .then(({ data }) => {
                console.log(data);
                this.setState({ data })
            })
            .catch(error => {
                console.dir(error)
            });
           
        this.setState({isLoading: false}); 
    }

    searchKits = () => {
        this.setState({isLoading: true}); 
        let token = JSON.parse(localStorage.getItem('access_token'));
        const { searchCode, currentPage } = this.state;

        cancel && cancel('Operation canceled due to new request.');

        const config = {
            headers: { Authorization: `Bearer ${token}` },
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
              })
        };

        if (searchCode !== "") {
            axios.get(`${baseUrl}/lab/filter/kits?kitCode=${searchCode}&status=${currentPage}`, config)
                .then(({ data }) => {
                    this.setState({ 
                        data: data ? data : [],
                        isLoading: false
                    })
                })
                .catch(error => {
                    if(axios.isCancel(error)) {
                        this.setState({isLoading: false}); 
                        return;
                    }
                    this.setState({isLoading: false}); 
                    console.dir(error)
                });
        }
        if (searchCode === "") this.loadKits();
    }

    receiveKit = async () => {
        this.setState({isLoading: true}); 
        let token = JSON.parse(localStorage.getItem('access_token'));

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const { id } = this.state;

        await axios.put(`${baseUrl}/lab/receive/dispatch/${id}`, {}, config)
            .then(({ data }) => {
                this.closeModal();
                this.loadKits();
            })
            .catch(error => {
                console.dir(error)
            });
        this.setState({isLoading: false}); 
    }

    setEndpoint = () => {
        const { currentPage } = this.state;
        let endpoint;
        switch(currentPage){
            case 'assigned':
                endpoint = 'all/assigned'
                break
            case 'received':
                endpoint = 'all/received'
                break
            case 'completed':
                endpoint = 'completed/tests'
                break
            default:
                endpoint = 'all/assigned' 
        }
        return endpoint;
    }

    setPage = (t) => {
        this.props.history.push({
            pathname: `/laboratory/kits/${t}`
        });
        this.setState({
            currentPage: t,
            data: null
        }, () => this.loadKits())
    }

    openModal = (kitCode, dispatchRider, id) => {
        this.setState({
            receiveModal: true,
            kitCode,
            dispatchRider,
            id
        })
    }

    closeModal = () => {
        this.setState({
            receiveModal: false,
            kitCode: '',
            dispatchRider: '',
            id: null
        })
    }

    navigate = (route) => {
      this.props.history.push({
        pathname: route
      })
    }

    render() {
        const { currentPage, data, receiveModal, kitCode, dispatchRider, searchCode, isLoading } = this.state;
        return (
            <main className="main-bg">
                <AuthNav
                    page='kits'
                    name="SYNLAB Nigeria"
                />
                <div className="kits-list">
                    <div className="top">
                        <div className="spacer"></div>
                        <div className="title-search">
                            <h4>
                                {currentPage === 'assigned' && 'All Assigned'}
                                {currentPage === 'received' && 'Received Kits'}
                                {currentPage === 'completed' && 'Completed Tests'}
                            </h4>
                            <div className="searchInputWrapper flex align-center">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.31461 0C8.25163 0 10.6292 2.3749 10.6292 5.3086L10.6293 5.30862C10.6293 6.62152 10.154 7.8232 9.34269 8.75907L12 11.4134L11.4127 12L8.7554 9.3457C7.83206 10.1424 6.62905 10.6172 5.31461 10.6172C2.37759 10.6172 0 8.22805 0 5.3086C0 2.38915 2.37759 0 5.31461 0ZM0.839243 5.3086C0.839243 7.7676 2.85292 9.779 5.3147 9.779C7.79005 9.779 9.79017 7.7676 9.79017 5.3086C9.79017 2.8496 7.77649 0.8382 5.3147 0.8382C2.85292 0.8382 0.839243 2.8496 0.839243 5.3086Z" fill="#262626" fillOpacity="0.5" />
                                </svg>
                                <input type='text' placeholder='Search for a kit' onChange={this.handleSearchInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div>
                            <div className="sideBarNav">
                                <ul>
                                    <li className={(currentPage === 'assigned' ? "focus" : "")} onClick={() => this.setPage('assigned')} >
                                        <div style={style.LinkStyle}>All Assigned</div>
                                    </li>

                                    <li className={(currentPage === 'received' ? "focus" : "")} onClick={() => this.setPage('received')}>
                                        <div style={style.LinkStyle}>Received Kit</div>
                                    </li>

                                    <li className={(currentPage === 'completed' ? "focus" : "")} onClick={() => this.setPage('completed')}>
                                        <div style={style.LinkStyle}>Completed Tests</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="table dispatchTable">
                            {
                                currentPage === 'assigned' && <AssignedKits data={data} openModal={this.openModal} searchCode={searchCode} />
                            }
                            {
                                currentPage === 'received' && <ReceivedKits data={data} navigate={this.navigate} searchCode={searchCode} />
                            }
                            {
                                currentPage === 'completed' && <CompletedTests data={data} searchCode={searchCode} />
                            }
                        </div>
                    </div>
                    { isLoading && <Loader />}
                </div>
                <Modal show={receiveModal} handleClose={this.closeModal}>
                    <div className="modal-container">
                        <h4>Receive a Kit</h4>
                        <p>Are you sure you want to receive the kit with the code {kitCode} from {dispatchRider}</p>

                        <div className="flex justifyBetween">
                            <div></div>

                            <div className="buttons-wrapper flex">
                                <p className="go-back" onClick={this.closeModal}>cancel</p>
                                <button className="modal-btn" onClick={this.receiveKit}>Receive</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </main>
        )
    }
}

export default withRouter(Kits);
