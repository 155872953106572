import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'

import Button from '../../../_common/Button'
import APP_URL from '../../../../util/api';
import Loader from '../../../_common/loader/Loader';
import { search } from '../../../../util/requestCreator';

const baseUrl = APP_URL;


class RiderListings extends Component {
  state = {
    dispatch: [],
    isLoading: false,
  }

  componentDidMount() {
    this.fetchRiders()
  }

  fetchRiders = () => {
    let token = JSON.parse(localStorage.getItem('access_token'))
    this.setState({isLoading: true})
    axios.get(`${baseUrl}/admin/view/riders?token=${token}`)
      .then(result => {
        let { data } = result;
        this.setState({isLoading: false, dispatch: data});
      })
      .catch(error => {
        this.setState({isLoading: false})
      })
  }

  searchRider = async (query) => {
    this.setState({isLoading: true})
    let token = JSON.parse(localStorage.getItem('access_token'))
    const res  = await search(`${baseUrl}/admin/search/rider?finder=${query}&token=${token}`)
    this.setState({dispatch: res, isLoading: false})
  }

 
  navigate = (route) => {
    this.props.history.push({
      pathname: route
    })
  }

  render() {
    return (
      <section className="lbDispatchSection">
          <div className="container">
            <div className="listingSection">
              <div className="tableFilter">
                <div className="flex justifyBetween">
                  <h4>Dispatch Riders</h4>

                  <div className="filter flex align-center" >
                    <div className="searchInputWrapper flex align-center">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.31461 0C8.25163 0 10.6292 2.3749 10.6292 5.3086L10.6293 5.30862C10.6293 6.62152 10.154 7.8232 9.34269 8.75907L12 11.4134L11.4127 12L8.7554 9.3457C7.83206 10.1424 6.62905 10.6172 5.31461 10.6172C2.37759 10.6172 0 8.22805 0 5.3086C0 2.38915 2.37759 0 5.31461 0ZM0.839243 5.3086C0.839243 7.7676 2.85292 9.779 5.3147 9.779C7.79005 9.779 9.79017 7.7676 9.79017 5.3086C9.79017 2.8496 7.77649 0.8382 5.3147 0.8382C2.85292 0.8382 0.839243 2.8496 0.839243 5.3086Z" fill="#262626" fillOpacity="0.5"/>
                      </svg>
                      <input type='text' placeholder='Search for dispatch rider' onChange={(e) => this.searchRider(e.target.value) } />
                    </div>
                    <Button
                      text='Add Dispatch Rider'
                      textColor="#FFF"
                      backgroundColor="#276DF7"
                      isPrimary={false}
                      fontSize="14px"
                      height="40px"
                      width="158px"
                      className="m-0"
                      onClick={() => this.navigate('/dispatch/add')}
                    />
                  </div>
                </div>
              </div>
              {this.state.isLoading && (<Loader />)}
            
              <div className="table dispatchTable">
                <table>
                  <thead>
                    <tr>
                      {/* <th className="th-center" style={{width: "114px", textAlign: "center"}}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="16" height="16" rx="2" fill="#262626" fillOpacity="0.1"/>
                          <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#262626" strokeOpacity="0.2"/>
                        </svg>
                      </th> */}
                      <th className="th-center" style={{width: "48px !important"}}></th>
                      <th>Name</th>
                      <th>ID</th>
                      <th>Phone Number</th>
                      <th>Location</th>
                      <th></th>
                    </tr>
                  </thead>
                
                <tbody>
                  {this.state.dispatch && this.state.dispatch.map((item, index) => (
                    <tr className="tr" key={index}>
                      <td className="avatar_td">
                        <div className="avatar">
                          {item.firstName && <span>{item.firstName[0]}</span>}
                          {item.lastName && <span>{item.lastName[0]}</span>}
                        </div>
                      </td>
                      <td>
                        {item.firstName} {" "} {item.lastName}
                      </td>
                      <td>{item.refId.toUpperCase()}</td>
                      <td>{item.phone}</td>
                      <td>{item.address}</td>
                      <td>
                        <button className='view-btn' onClick={() => this.navigate(`/dispatch/profile/${item.id}`)}>View</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                
              </table>
            </div>
            </div>
          </div>
        </section>
    )
  }
}

export default withRouter(RiderListings)