import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Form from './components/form/Form';
import Login from './components/dashboard/Auth/Login';
import DashboardIndex from './components/dashboard/superAdmin/Index';
import Labs from './components/dashboard/superAdmin/Labs/Labs';
import DispatchProfile from './components/dashboard/superAdmin/Dispatch/Profile';
import BlockedDispatchProfile from './components/dashboard/superAdmin/Dispatch/BlockedProfile'
import LabsProfile from './components/dashboard/superAdmin/Labs/LabsProfile';
import BlockedProfile from './components/dashboard/superAdmin/Labs/BlockedProfile';
import Admins from './components/dashboard/superAdmin/Admin/Index';
import AdminProfile from './components/dashboard/superAdmin/Admin/AdminProfile';
import CreateDispatchRider from './components/dashboard/superAdmin/Dispatch/CreateDispatchRider';
import AddAdmin from './components/dashboard/superAdmin/Admin/AddAdmin';
import BlockedAdminProfile from './components/dashboard/superAdmin/Admin/BlockedProfile';
import AddLab from './components/dashboard/superAdmin/Labs/AddLab';
import TesterListing from './components/dashboard/superAdmin/Testers/TesterListing';
import Kits from './components/dashboard/labPersonnel/kits/Kits';
import UploadResult from './components/dashboard/labPersonnel/kits/UploadResult';
import Overview from './components/dashboard/overview/Overview';

import { ProtectedRoute } from './components/dashboard/Auth/AuthenticatedPages';
import TesterProfile from './components/dashboard/superAdmin/Testers/TesterProfile';
import AssignKitToTester from './components/dashboard/superAdmin/Testers/AssignKit';
import AuthProfile from './components/dashboard/Auth/AuthProfile';
import ForgotPassword from './components/dashboard/Auth/ForgotPassword';
import ResetPassword from './components/dashboard/Auth/ResetPassword';

const App = () => {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path='/' exact component={Form} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/password/forgot' component={ForgotPassword} />
          <Route exact path='/password/reset' component={ResetPassword} />
          <ProtectedRoute exact path='/overview' component={Overview} />
          <ProtectedRoute exact path='/dispatch' component={DashboardIndex} />
          <ProtectedRoute exact path='/labs' component={Labs} />
          <ProtectedRoute exact path='/labs/profile/:id' component={LabsProfile} />
          <ProtectedRoute exact path='/labs/profile/:id/blocked' component={BlockedProfile} />
          <ProtectedRoute exact path='/lab/add' component={AddLab} />
          <ProtectedRoute exact path='/dispatch/profile/:id' component={DispatchProfile} />
          <ProtectedRoute exact path='/dispatch/profile/:id/blocked' component={BlockedDispatchProfile} />
          <ProtectedRoute exact path='/dispatch/add' component={CreateDispatchRider} />
          <Route exact path="/testers" render={() => (
            <Redirect to='/testers/unassigned' />
          )} />
          <ProtectedRoute exact path='/testers/:page' component={TesterListing} />
          <ProtectedRoute exact path='/tester/profile/:id' component={TesterProfile} />
          <ProtectedRoute exact path='/testers/assign/:id' component={AssignKitToTester} />
          <ProtectedRoute exact path='/admin' component={Admins} />
          <ProtectedRoute exact path='/admin/profile/:id' component={AdminProfile} />
          <ProtectedRoute exact path='/admin/profile/:id/blocked' component={BlockedAdminProfile} />
          <ProtectedRoute exact path='/admin/add' component={AddAdmin} />
          <ProtectedRoute exact path='/profile/me' component={AuthProfile} />
          <Route exact path="/laboratory" render={() => (
            <Redirect to='/laboratory/kits/assigned' />
          )} />
          <ProtectedRoute exact path='/laboratory/kits/:value' component={Kits} />
          <ProtectedRoute exact path='/laboratory/kit/:id/:code' component={UploadResult} />
          <Route path="*" component={() => <div>Page Not Found</div>} />
        </Switch>
      </Router>
    </React.Fragment>
  )
}

export default App;