import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import DatePicker from "react-datepicker";
import { ClockIcon } from '../../../_common/Icons'
import APP_URL from '../../../../util/api'
import Loader from '../../../_common/loader/Loader';

import "react-datepicker/dist/react-datepicker.css";

const baseUrl = APP_URL;

export default class Reschedule extends Component {
  state = {
    datePickerIsOpen: false,
    isLoading: false,
  }

  setDeliveryDate = (date) => {
    this.setState({
      newDeliveryDate: date,
    }, () => this.openDatePicker())
  }

  openDatePicker = () => {
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  };

  rescheduleDelivery = async (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });
    const { newDeliveryDate } = this.state;
    const { id } = this.props;

    let token = JSON.parse(localStorage.getItem('access_token'));

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const requestBody = {
      deliveryDate: moment(newDeliveryDate).format('YYYY-MM-DD')
    }

    await axios.put(`${baseUrl}/admin/cases/edit/${id}`, requestBody, config)
      .then(({ data }) => {
        console.log(data);
        this.props.onSuccessClose();
      })
      .catch(error => {
        console.log(error)
      });
    this.setState({ isLoading: false })
  }

  render() {
    const { datePickerIsOpen, newDeliveryDate, isLoading } = this.state;
    const { firstName, lastName, deliveryDate, closeModal } = this.props;
    const date = newDeliveryDate ? moment(newDeliveryDate).format('LL') : moment(deliveryDate).format('LL');
    const disableButton = !newDeliveryDate || moment(deliveryDate).format('LL') === moment(newDeliveryDate).format('LL');
    return (
      <div className="modal-container">
        <h4>Reschedule Delivery for <br /> {firstName} {lastName}</h4>
        <div className="date-div">
          <ClockIcon />
          <p>{date}</p>
          <span onClick={this.openDatePicker}>
            {datePickerIsOpen ? 'Close Picker' : 'Edit'}
          </span>
        </div>
        {
          deliveryDate &&
          <DatePicker
            className="date-picker"
            minDate={moment().toDate()}
            onChange={date => this.setDeliveryDate(date)}
            open={datePickerIsOpen}
          />
        }

        <div className="flex justifyBetween">
          <div></div>

          <div className="buttons-wrapper flex">
            <p className="go-back" onClick={closeModal}>cancel</p>
            <button
              className="modal-btn"
              onClick={this.rescheduleDelivery}
              disabled={disableButton}
            >
              Save
                </button>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    )
  }
}
