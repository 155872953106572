import React from "react";

export const TestersIcon = ({ color = "#C9C9D6" }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.4 5.1999C3.2825 5.1999 4 4.4824 4 3.5999C4 2.7174 3.2825 1.9999 2.4 1.9999C1.5175 1.9999 0.8 2.7174 0.8 3.5999C0.8 4.4824 1.5175 5.1999 2.4 5.1999ZM13.6 5.1999C14.4825 5.1999 15.2 4.4824 15.2 3.5999C15.2 2.7174 14.4825 1.9999 13.6 1.9999C12.7175 1.9999 12 2.7174 12 3.5999C12 4.4824 12.7175 5.1999 13.6 5.1999ZM14.4 5.9999H12.8C12.36 5.9999 11.9625 6.1774 11.6725 6.4649C12.68 7.0174 13.395 8.0149 13.55 9.1999H15.2C15.6425 9.1999 16 8.8424 16 8.3999V7.5999C16 6.7174 15.2825 5.9999 14.4 5.9999ZM8 5.9999C9.5475 5.9999 10.8 4.7474 10.8 3.1999C10.8 1.6524 9.5475 0.399902 8 0.399902C6.4525 0.399902 5.2 1.6524 5.2 3.1999C5.2 4.7474 6.4525 5.9999 8 5.9999ZM9.92 6.7999H9.7125C9.1925 7.0499 8.615 7.1999 8 7.1999C7.385 7.1999 6.81 7.0499 6.2875 6.7999H6.08C4.49 6.7999 3.2 8.0899 3.2 9.6799V10.3999C3.2 11.0624 3.7375 11.5999 4.4 11.5999H11.6C12.2625 11.5999 12.8 11.0624 12.8 10.3999V9.6799C12.8 8.0899 11.51 6.7999 9.92 6.7999ZM4.3275 6.4649C4.0375 6.1774 3.64 5.9999 3.2 5.9999H1.6C0.7175 5.9999 0 6.7174 0 7.5999V8.3999C0 8.8424 0.3575 9.1999 0.8 9.1999H2.4475C2.605 8.0149 3.32 7.0174 4.3275 6.4649Z"
      fill={color}
    />
  </svg>
);

export const DispatchIcon = ({ color = "#C9C9D6" }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.55556 3.33341H12.4444V8.66675H3.55556V3.33341ZM14.6667 6.00008C14.6667 6.73647 15.2636 7.33341 16 7.33341V10.0001C16 10.7365 15.4031 11.3334 14.6667 11.3334H1.33333C0.596944 11.3334 0 10.7365 0 10.0001V7.33341C0.736389 7.33341 1.33333 6.73647 1.33333 6.00008C1.33333 5.26369 0.736389 4.66675 0 4.66675V2.00008C0 1.26369 0.596944 0.666748 1.33333 0.666748H14.6667C15.4031 0.666748 16 1.26369 16 2.00008V4.66675C15.2636 4.66675 14.6667 5.26369 14.6667 6.00008ZM13.3333 3.11119C13.3333 2.743 13.0349 2.44453 12.6667 2.44453H3.33333C2.96514 2.44453 2.66667 2.743 2.66667 3.11119V8.88897C2.66667 9.25716 2.96514 9.55564 3.33333 9.55564H12.6667C13.0349 9.55564 13.3333 9.25716 13.3333 8.88897V3.11119Z"
      fill={color}
    />
  </svg>
);

export const LabsIcon = ({ color = "#C9C9D6" }) => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.50187 14.1985C3.50219 14.395 3.56031 14.5875 3.66937 14.7513L4.20344 15.5541C4.29467 15.6913 4.41841 15.8039 4.56366 15.8817C4.7089 15.9596 4.87114 16.0003 5.03594 16.0003H6.96438C7.12917 16.0003 7.29141 15.9596 7.43665 15.8817C7.5819 15.8039 7.70564 15.6913 7.79688 15.5541L8.33094 14.7513C8.43995 14.5875 8.49822 14.3952 8.49844 14.1985L8.49969 13H3.50031L3.50187 14.1985ZM0.5 5.50003C0.5 6.88659 1.01406 8.15159 1.86125 9.11815C2.3775 9.70721 3.185 10.9378 3.49281 11.976C3.49406 11.9841 3.495 11.9922 3.49625 12.0003H8.50375C8.505 11.9922 8.50594 11.9844 8.50719 11.976C8.815 10.9378 9.6225 9.70721 10.1388 9.11815C10.9859 8.15159 11.5 6.88659 11.5 5.50003C11.5 2.45659 9.02844 -0.00934836 5.98281 2.66431e-05C2.795 0.00971414 0.5 2.59284 0.5 5.50003ZM6 3.00003C4.62156 3.00003 3.5 4.12159 3.5 5.50003C3.5 5.77628 3.27625 6.00003 3 6.00003C2.72375 6.00003 2.5 5.77628 2.5 5.50003C2.5 3.57003 4.07 2.00003 6 2.00003C6.27625 2.00003 6.5 2.22378 6.5 2.50003C6.5 2.77628 6.27625 3.00003 6 3.00003Z"
      fill={color}
    />
  </svg>
);

export const AdminIcon = ({ color = "#C9C9D6" }) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 8C9.20938 8 11 6.20937 11 4C11 1.79063 9.20938 0 7 0C4.79063 0 3 1.79063 3 4C3 6.20937 4.79063 8 7 8ZM9.99375 9.01875L8.5 15L7.5 10.75L8.5 9H5.5L6.5 10.75L5.5 15L4.00625 9.01875C1.77812 9.125 0 10.9469 0 13.2V14.5C0 15.3281 0.671875 16 1.5 16H12.5C13.3281 16 14 15.3281 14 14.5V13.2C14 10.9469 12.2219 9.125 9.99375 9.01875Z"
      fill={color}
    />
  </svg>
);

export const KitsIcon = ({ color }) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.998698 12H12.332C12.6987 12 12.9987 11.7 12.9987 11.3333V10.6667C12.9987 10.3 12.6987 10 12.332 10H0.998698C0.632031 10 0.332031 10.3 0.332031 10.6667V11.3333C0.332031 11.7 0.632031 12 0.998698 12ZM12.332 3.33333H0.998698C0.632031 3.33333 0.332031 3.63333 0.332031 4V8C0.332031 8.36667 0.632031 8.66667 0.998698 8.66667H12.332C12.6987 8.66667 12.9987 8.36667 12.9987 8V4C12.9987 3.63333 12.6987 3.33333 12.332 3.33333ZM0.332031 0.666667V1.33333C0.332031 1.7 0.632031 2 0.998698 2H12.332C12.6987 2 12.9987 1.7 12.9987 1.33333V0.666667C12.9987 0.3 12.6987 0 12.332 0H0.998698C0.632031 0 0.332031 0.3 0.332031 0.666667Z"
      fill={color}
    />
  </svg>
);

export const OverviewIcon = ({ color = "#C9C9D6" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5233 8.94116H8.54418L13.1921 13.5891C13.3698 13.7667 13.6621 13.7812 13.8448 13.6091C14.983 12.5367 15.7659 11.0912 15.9957 9.46616C16.0351 9.18793 15.8042 8.94116 15.5233 8.94116ZM15.0577 7.03528C14.8154 3.52176 12.0077 0.714119 8.49418 0.471767C8.22595 0.453237 8.00006 0.679413 8.00006 0.948236V7.5294H14.5815C14.8504 7.5294 15.0762 7.30352 15.0577 7.03528ZM6.5883 8.94116V1.96206C6.5883 1.68118 6.34153 1.45029 6.06359 1.48971C2.55859 1.985 -0.120524 5.04705 0.00418193 8.71675C0.132417 12.4856 3.37742 15.5761 7.14801 15.5288C8.63036 15.5103 10.0001 15.0326 11.1262 14.2338C11.3586 14.0691 11.3739 13.727 11.1724 13.5256L6.5883 8.94116Z"
      fill={color}
    />
  </svg>
);

export const ClockIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <path
        d="M10.9915 1.83337C5.93148 1.83337 1.83398 5.94004 1.83398 11C1.83398 16.06 5.93148 20.1667 10.9915 20.1667C16.0607 20.1667 20.1673 16.06 20.1673 11C20.1673 5.94004 16.0607 1.83337 10.9915 1.83337ZM11.0007 18.3334C6.94898 18.3334 3.66732 15.0517 3.66732 11C3.66732 6.94837 6.94898 3.66671 11.0007 3.66671C15.0523 3.66671 18.334 6.94837 18.334 11C18.334 15.0517 15.0523 18.3334 11.0007 18.3334ZM10.799 6.41671H10.744C10.3773 6.41671 10.084 6.71004 10.084 7.07671V11.4034C10.084 11.7242 10.249 12.0267 10.5332 12.1917L14.3373 14.4742C14.649 14.6575 15.0523 14.5659 15.2357 14.2542C15.4282 13.9425 15.3273 13.53 15.0065 13.3467L11.459 11.2384V7.07671C11.459 6.71004 11.1657 6.41671 10.799 6.41671Z"
        fill="#3B3B3B"
      />
    </g>
  </svg>
);

export const GoBackIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0003 23.8332C18.9834 23.8332 23.8337 18.9829 23.8337 12.9998C23.8337 7.01675 18.9834 2.1665 13.0003 2.1665C7.01724 2.1665 2.16699 7.01675 2.16699 12.9998C2.16699 18.9829 7.01724 23.8332 13.0003 23.8332Z"
      stroke="#276DF7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1663 8.6665L10.833 12.9998L15.1663 17.3332"
      stroke="#276DF7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
