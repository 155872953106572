import React from 'react'
import InputRow from './InputRow'


const FormInput = ({label, value, onChange, placeHolder, error, name, type, hasShowText, togglePassword}) => (
  <InputRow
    {...{ label, value}} >
      <div className="flex flex-column h-30 align-center">
        <input 
          type={type}
          value={value}
          placeholder={placeHolder}
          name={name}
          onChange={onChange}
          className="borderless"
          />
          {hasShowText && (
            <p
              onClick={togglePassword} 
              className="showPassword">
                {type === 'password' ? 'Show' : 'Hide'}
              </p>
          )}
      </div>
      
    </InputRow>
)

export default FormInput