import React, { Component } from 'react'
import moment from 'moment'
import statuses from '../../../util/statuses'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import REACT_APP_URL from '../../../util/api'
import Loader from '../loader/Loader'
const baseUrl = REACT_APP_URL

class AllDeliveriesTable extends Component {
  state = {
    redirectTo: '',
    deliveries: []
  }

  toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  navigate = (route) => {
    this.props.history.push({
      pathname: route
    })
  }

  componentDidMount() {
    const { match: { params } } = this.props;

    let token = JSON.parse(localStorage.getItem('access_token'));

    this.setState({isLoading: true})
    axios.get(`${baseUrl}/admin/rider/${params.id}/views?token=${token}`)
      .then(({ data }) => {
        this.setState({ deliveries: data.deliveries, isLoading: false })
      })
      .catch(error => {
        this.setState({isLoading: false})
      })

  }

  render() {
    const { deliveries, isLoading } = this.state;

    return (
      <div className="table dispatchTable">
        <table>
          <thead>
            <tr>
              <th>Name & Kit Code</th>
              <th>Phone Number</th>
              <th>Delivery Date</th>
              <th>Location</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {isLoading && <Loader />}
            {!isLoading && deliveries && deliveries.map((item, index) => (
              <tr className="tr" key={index}>
                <td>
                  <div className="flex flexColumn">
                    <p>{this.toTitleCase(item.case.firstName)} {" "} {this.toTitleCase(item.case.lastName)}</p>
                    <p>{item.kitCode}</p>
                  </div>
                </td>
                <td>{item.case.phone_no}</td>
                <td>{moment(item.case.deliveryDate).format('LL')}</td>
                <td>{(item.lab && item.lab.lab_address) ? item.lab.lab_address : ''}</td>
                <td>
                  <button className={`badges ${item.status}`} onClick={e => this.navigate('/dispatch/profile')}>{statuses[item.status]}</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {
          deliveries && deliveries.length === 0 && <h3>No deliveries to display at this moment</h3>
        }
      </div>

    )
  }
}

export default withRouter(AllDeliveriesTable);