
import React, { Component } from 'react'
import moment from 'moment'
import statuses from '../../../util/statuses'


class TestResultsTable extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="table dispatchTable">
        <table>
          <thead>
            <tr>
              <th>Kit Code</th>
              <th>Lab</th>
              <th>Date Uploaded</th>
              <th>Result</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {data && data.map((item, index) => {
              let sendTo;
              if (item.case.result === 'negative') {
                sendTo = 'Send to User'
              } else if (item.case.result === 'positive') {
                sendTo = 'Send to NCDC'
              }
              return (
                <tr className="tr" key={index}>
                  <td style={{ fontWeight: '600', color: '#222' }}>
                    {item.kitCode}
                  </td>
                  <td>{item.lab && <span>{item.lab.name}</span>}</td>
                  <td>{moment(item['case']['resultUploadedAt']).format('LL')}</td>
                  <td style={{ marginTop: "15%", marginLeft: "1px !important" }} className={`badges ${item.case.result}`}>{statuses[item.case.result]}</td>
                  <td>
                    {!sendTo && <span></span>}
                    {item.case && item.case.id && sendTo && <button className="view-btn w-auto plr10" onClick={() => this.props.sendResults(item.case.id)}>{sendTo}</button>}
                  </td>
                </tr>
              )
            })}

            {
              data && data.length === 0 && 
              <tr>
                <td colSpan="5">
                  <h3 style={{padding:'30px', textAlign:'center'}}>No test results at the moment</h3>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    )
  }
}

export default TestResultsTable;