import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import statuses from '../../../util/statuses'

class AssignedTestersTable extends Component {

  navigate = (route) => {
    this.props.history.push({
      pathname: route
    })
  }

  render() {
    const { data } = this.props;
    return (
      <div className="table dispatchTable">
        <table>
          <thead>
            <tr>
              <th>Name & Kit Code</th>
              <th>Phone Number</th>
              <th>Delivery Date</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
            data && data.map((item, index) => (
              <tr className="tr" key={index}>
                <td>
                  <div className="flex flexColumn name-email">
                    <div>{item.firstName} {item.lastName}</div>
                    <div>{item.dispatch.kitCode}</div>
                  </div>
                </td>
                <td>{item.phone_no} </td>
                <td>{moment(item.deliveryDate).format('LL')}</td>
                <td>
                  <div className={'badges ' + (item.dispatch.status ? item.dispatch.status : 'default')}>
                    <span>{statuses[item.dispatch.status]}</span>
                  </div>
                </td>

                <td><button className="view-btn" onClick={() => this.navigate(`/tester/profile/${item.id}`)}>View</button></td>
              </tr>
            ))}
            {
              data && data.length === 0 && 
              <tr>
                <td colSpan="5">
                  <h3 style={{padding:'30px', textAlign:'center'}}>No assigned testers at the moment</h3>
                </td>
              </tr>
            }
          </tbody>
        </table>          
      </div>        
    )
  }
}

export default withRouter(AssignedTestersTable);